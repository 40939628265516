import React from 'react';
import CreateCommentContainer from '../containers/CreateCommentContainer';
import Divider from '@material-ui/core/Divider'
import "./CommentSection.css"

const CommentSection = ({children, teamId, boardId, feedbackId}) => {
    return (
        <div>
            <Divider className="CommentSectionDivider" variant="middle" />
            <div className="CommentContainer">
                {children}
            </div>
            <CreateCommentContainer teamId={teamId} boardId={boardId} feedbackId={feedbackId}/>
        </div>
    );
};

export default CommentSection;