import React, {useEffect} from 'react'
import '../App.css'
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import Loading from "../components/Loading";
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import UserForm from "../components/UserForm";
import {userUpdate} from "../redux/actions";
import {getUserId} from "../utilities/UserUtility";

function UserFirestoreContainer({userId, currentUser, onClickUpdate}) {
    if (!isLoaded(currentUser)) {
        return (
            <Loading/>
        );
    }

    return (
        <UserForm email={currentUser.email} displayName={currentUser.displayName} userId={userId} onClickUpdate={onClickUpdate}/>
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.firestore.ordered.user && state.firestore.ordered.user[0].id,
        currentUser: state.firestore.data.currentUser,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickUpdate: (userId, name, email) => {
            return dispatch(userUpdate(userId, name, email));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const userId = getUserId(state.firebase);
        return [
            {
                collection: 'users',
                doc: userId,
                storeAs: 'currentUser'
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UserFirestoreContainer)
