import React from 'react'
import '../App.css'
import {compose} from "redux";
import {firestoreConnect, isLoaded, isEmpty} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import {Tab} from '@material-ui/core';
import {getTeam} from "../redux/actions";
import usePromise from 'react-promise';

function TeamName({teamId, team, getTeam}) {
    if (!isLoaded(team) && isEmpty(team)) {
        return null;
    }
    return <div>{team && team.name}</div>
}

const mapStateToProps = (state, ownProps) => {
    const teams = state.firestore.data.teams;
    return {
        team: teams && teams[ownProps.teamId],
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

export default compose(
    firestoreConnect((props, state) => {
        if (!props.teamId) {
            return [];
        }

        return [
            {
                collection: 'teams',
                doc: props.teamId,
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(TeamName)
