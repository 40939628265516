import React from 'react'
import '../App.css'
import {compose} from "redux";
import {firestoreConnect, isLoaded, isEmpty} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import TeamFormContainer from "../containers/TeamFormContainer";
import Loading from "../components/Loading";
import {objectToArray} from "../utilities/ArrayUtilities";
import {teamDelete, teamUserDelete, updateTeam} from "../redux/actions";
import TeamForm from "../components/TeamForm";
import {getUserId} from "../utilities/UserUtility";

function TeamFormFirestoreContainer({userId, team, teamId, errors, isCreating, allowDelete, teamUsers, isCreatingTeam, allowDeletingOwnTeamUser,
                                        onClickDoneEditing, onClickTeamUserDelete, onClickTeamDelete
}) {
    if (!isLoaded(team, teamId, teamUsers)) {
        return (<Loading/>);
    }

    if (isEmpty(teamUsers)) {
        return (<Loading/>);
    }

    return (
        <div>
            {team &&
                <TeamForm
                    name={team.name}
                    teamId={teamId}
                    userId={userId}
                    teamCreatedAt={team.createdAt}
                    teamUsers={teamUsers}
                    isCreating={isCreatingTeam}
                    allowDelete={allowDelete}
                    allowDeletingOwnTeamUser={allowDeletingOwnTeamUser}
                    onClickDoneEditing={onClickDoneEditing}
                    onClickTeamUserDelete={onClickTeamUserDelete}
                    onClickTeamDelete={onClickTeamDelete}
                />
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: getUserId(state.firebase),
        team: state.firestore.data.currentTeam,
        teamId: ownProps.teamId,
        errors: state.firestore.errors,
        teamUsers: state.firestore.data.currentTeamUsers && objectToArray(state.firestore.data.currentTeamUsers),
        isCreatingTeam: state.team.isCreatingTeam,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickDoneEditing: (teamName) => {
            return dispatch(updateTeam(ownProps.teamId, teamName));
        },
        onClickTeamUserDelete: (teamUserId) => {
            return dispatch(teamUserDelete(teamUserId));
        },
        onClickTeamDelete: (teamUsers) => {
            return dispatch(teamDelete(ownProps.teamId, teamUsers));
        }
    }
};


export default compose(
    firestoreConnect((props, state) => {
        if (!props.teamId) return [];
        return [
            {
                collection: 'teams',
                doc: props.teamId,
                storeAs: 'currentTeam'
            },
            {
                collection: 'teamUsers',
                where: [
                    ['teamId', '==', props.teamId],
                ],
                storeAs: 'currentTeamUsers'
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(TeamFormFirestoreContainer)
