import React, {useState, useEffect} from 'react'
import './Board.css'
import CreateBoardContainer from "../containers/CreateBoardContainer";
import TextField from "@material-ui/core/TextField/TextField";
import onBlur from '../utilities/Blur';
import DeleteBoard from "./DeleteBoard";
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import IconButton from "@material-ui/core/IconButton/IconButton";
import DemoFirestoreContainer from "../firestoreContainers/DemoFirestoreContainer";
import Button from "@material-ui/core/Button/Button";

const Board =({children, user, userId, teamId, name, isEditing, boardId, orderedBoards, heartsLeft,
                  onClickDoneEditing, onClickToggleIsEditing, onClickDelete, onClickNextBoard, onClickPreviousBoard,
}) => {
    let [boardName, changeBoardName] = useState(name);
    let [boardIdState, changeBoardIdState] = useState(boardId);

    useEffect(() => {
        // If the board id changed, that means we need to use "name", which is input into this component,
        // instead of the boardName state that's been saved in the past inside this component.
        if (boardId !== boardIdState) {
            changeBoardName(name);
            changeBoardIdState(boardId);
        }
    });

    let saveBoardName = (boardName) => {
        if (boardName.trim().length === 0) {
            return;
        }
        onClickDoneEditing(boardName);
    };

    // If no board exists, create one.
    if (typeof name === 'undefined') {
        // onClickBoardCreate('My Board');
        return '';
    }

    const indexOfCurrentBoard = orderedBoards.map(board => board.id).indexOf(boardId);
    const showBoardPrevious = indexOfCurrentBoard < orderedBoards.length - 1;
    const showBoardNext = indexOfCurrentBoard !== 0;

    return (
        <div className="Board">
            <div className="BoardHeader">
                {isEditing &&
                    <form className="BoardNameEditField" onSubmit={(e) => { e.preventDefault(); saveBoardName(boardName);}} onBlur={(e) => onBlur(e, () => onClickDoneEditing(boardName))}>
                        <h2><TextField value={boardName} autoFocus onChange={(e) => changeBoardName(e.target.value)} id="outlined-basic" className="BoardNameEditInput" size="large" variant="outlined" /></h2>
                    </form>
                }
                {!isEditing &&
                    <div className="SprintNameContainer">
                        <h2 className="SprintName">
                            <IconButton aria-label="edit" color="inherit" style={{visibility: showBoardPrevious ? 'visible' : 'hidden'}} onClick={() => onClickPreviousBoard(orderedBoards, boardId)}>
                                <ChevronLeft/>
                            </IconButton>
                            <span className="SprintNameText" onClick={() => onClickToggleIsEditing(true)}>{name}</span>
                            <IconButton aria-label="edit" color="inherit" style={{visibility: showBoardNext ? 'visible' : 'hidden'}} onClick={() => onClickNextBoard(orderedBoards, boardId)}>
                                <ChevronRight/>
                            </IconButton>
                        </h2>
                        <div className="DeleteBoard">
                            <DeleteBoard onClickDelete={onClickDelete} />
                        </div>
                    </div>
                }
                {heartsLeft !== null &&
                    <p className="HeartsLeft">Likes left: <strong>{heartsLeft}</strong></p>
                }
                <div className="DemoButton">
                    <DemoFirestoreContainer teamId={teamId} boardId={boardId}/>
                </div>
                <div className="BoardActions">
                    <CreateBoardContainer user={user} userId={userId} teamId={teamId} boardId={boardId}/>
                </div>
            </div>
            <div className="BoardColumns">{children}</div>
        </div>
    )
};

export default Board;

