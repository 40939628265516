import {useSelector} from "react-redux";
import {isLoaded} from "react-redux-firebase";
import React from "react";

function AuthIsLoaded({ children }) {
    const auth = useSelector(state => state.firebase.auth)
    // Show a splash screen below if you want while auth is loading
    if (!isLoaded(auth)) return <div></div>;

    return children
}

export default AuthIsLoaded;
