import React from "react";
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from '@material-ui/core/styles';
import {userUpdate} from "../redux/actions";
import connect from "react-redux/es/connect/connect";
import Loading from "../components/Loading";
import './TeamSelectorFirestoreContainer.css';
import Avatar from "@material-ui/core/Avatar/Avatar";
import Group from "@material-ui/icons/Group";
import CreateIcon from '@material-ui/icons/Add';
import {compose} from "redux";
import {firestoreConnect, populate, isLoaded} from "react-redux-firebase";
import {setTeamTabViewIndex, setTeamIsCreating} from '../redux/actions';
import TeamFormFirestoreContainer from "./TeamFormFirestoreContainer";
import {useHistory} from 'react-router-dom';
import BackButton from '../components/BackButton';
import {getUserId} from "../utilities/UserUtility";
import TeamNameTab from '../firestoreContainers/TeamNameTab';
import {groupBy} from "../utilities/ArrayUtilities";

const useStyles = makeStyles(theme => ({
    tabContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function getTeams(teamUsers) {
    if (!teamUsers) {
        return [];
    }

    let teamIds = [];
    Object.keys(teamUsers).forEach((teamUserId) => {
        const teamData = teamUsers[teamUserId];
        // Make sure team hasn't been deleted
        if (teamData === null || typeof teamData.teamId === 'undefined') {
            return;
        }
        teamIds.push(teamData.teamId);
    });
    return teamIds;
}

const TeamSelector = ({teamId, teamUsers, teamTabViewIndex,
                          setTeamTabViewIndex, setTeamIsCreating
}) => {
    const classes = useStyles();

    if (!isLoaded(teamUsers)) {
        return <Loading/>;
    }

    const teamIds = getTeams(teamUsers);
    const allowDeletingOwnTeamUser = teamUsers && Object.keys(teamUsers).length > 1;

    return (
        <div>
            <BackButton/>
            <Paper className="TeamSelectorContainer" elevation={2}>
                <div className="TeamSetupTitle">
                    <Avatar color="primary" className="TeamSetupAvatar">
                        <Group />
                    </Avatar>
                    <div className="TeamFormMargin">
                        <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
                            Team Setup
                        </Typography>
                    </div>
                    <hr style={{border: '1px solid #f3f3f3'}}/>
                </div>
                <div>
                    <Button
                        color="primary"
                        className="CreateTeamButton"
                        onClick={() => setTeamIsCreating(true)}
                    >
                        <CreateIcon /> Create Team
                    </Button>
                    <br />
                    <div className={classes.tabContainer}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={teamTabViewIndex}
                            onChange={(e, newValue) => {setTeamTabViewIndex(newValue)}}
                            onClick={() => {setTeamIsCreating(false)}}
                            className={classes.tabs}
                        >
                            {teamIds.map((teamId) => (
                                <TeamNameTab key={teamId} teamid={teamId}/>
                            ))}

                        </Tabs>
                        <TabPanel className="TeamSelectorTabPanel">
                            {teamIds[teamTabViewIndex] &&
                                <TeamFormFirestoreContainer
                                    teamId={teamIds[teamTabViewIndex]}
                                    allowDelete={teamIds.length > 1}
                                    allowDeletingOwnTeamUser={allowDeletingOwnTeamUser}
                                />
                            }
                        </TabPanel>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        teamUsers: state.firestore.data.teamUsers,
        teamTabViewIndex: state.team.teamTabViewIndex,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickUpdate: (userId, name, email) => {
            return dispatch(userUpdate(userId, name, email));
        },
        setTeamTabViewIndex: (index) => {
            return dispatch(setTeamTabViewIndex(index));
        },
        setTeamIsCreating: (isCreating) => {
            return dispatch(setTeamIsCreating(isCreating));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const userId = getUserId(state.firebase);
        return [
            {
                collection: 'teamUsers',
                where: [
                    ['userId', '==', userId]
                ]
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(TeamSelector)