import React from 'react';
import {storeSlackCode, slackUpdateDaysAfterRetroToSendMessage, slackUpdateIsActive, slackUpdateSendTestMessage} from "../redux/actions";
import connect from "react-redux/es/connect/connect";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import BackButton from "../components/BackButton";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Icon from "@material-ui/core/Icon/Icon";
import Typography from "@material-ui/core/Typography/Typography";
import "./SlackContainer.css";
import slackImage from "../images/slack.png";

const Slack = ({code, teamId, integrationSlack,
                       addSlackCode, updateDaysAfterRetroToSendMessage, updateIsActive, sendTestMessage
}) => {
    const [isSlackSetUp, setIsSlackSetUp] = React.useState(false);
    const [daysAfterRetroToSendMessage, setDaysAfterRetroToSendMessage] = React.useState(integrationSlack ? integrationSlack.daysAfterRetroToSendMessage : 5);
    const [didDaysAfterRetroToSendMessageChange, setDidDaysAfterRetroToSendMessageChange] = React.useState(false);
    const [isIntegrationActive, setIsIntegrationActive]= React.useState(false);

    const changeDaysAfterRetroToSendMessage = (value) => {
        setDaysAfterRetroToSendMessage(value);
        setDidDaysAfterRetroToSendMessageChange(true);
    };

    const submitForm = () => {
        updateDaysAfterRetroToSendMessage(parseInt(daysAfterRetroToSendMessage));
        setDidDaysAfterRetroToSendMessageChange(false);
    };

    const deleteIntegration = () => {
        updateIsActive(false);
    };

    const enableIntegration = () => {
        updateIsActive(true);
    };

    if (integrationSlack && integrationSlack.isActive === true && isIntegrationActive === false) {
        setIsIntegrationActive(true);
    }

    if (integrationSlack && integrationSlack.isActive === false && isIntegrationActive === true) {
        setIsIntegrationActive(false);
    }

    let integrationError = integrationSlack && integrationSlack.data && integrationSlack.data.ok === false;
    if (code && (integrationSlack === null || integrationError) && isSlackSetUp === false) {
        addSlackCode(code);
        setIsSlackSetUp(true);
        setIsIntegrationActive(true);
    }

    if (integrationSlack && isSlackSetUp === false) {
        setIsSlackSetUp(true);
    }

    let isSendingTestMessage = integrationSlack && integrationSlack.sendTestMessage;
    let sendTestMessageButton = isSendingTestMessage ? "Sending..." : "Send Test Message";

    return (
        <div>
            <div>
                <BackButton/>
                <div style={{marginTop: '20px'}}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <div style={{maxWidth: 400}}>
                            <Paper style={{padding: "20px 20px 0 20px"}} elevation={2}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <img src={slackImage} height="75px"></img>
                                    <div className="TeamFormMargin">
                                        <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
                                            Slack Integration
                                        </Typography>
                                    </div>
                                    {integrationSlack && !isIntegrationActive && !integrationError &&
                                        <div>
                                            <p>Your Slack integration is currently disabled. Press the button below to enable it.</p>
                                            <Grid container
                                                  direction="column"
                                                  justify="center"
                                                  alignItems="center"
                                            >
                                                <Button
                                                    onClick={enableIntegration}
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    type="submit">
                                                    Enable
                                                </Button>
                                            </Grid>
                                        </div>
                                    }
                                    {isSlackSetUp && isIntegrationActive && !integrationError &&
                                        <div>
                                            <form style={{minWidth: 250, fontSize: "18px", lineHeight: "35px"}} onSubmit={(e) => {
                                                e.preventDefault(); submitForm();
                                            }}>
                                                <Grid item xs={12} md={12}>
                                                    <br/>
                                                    Send my team a Slack message containing action items &nbsp;
                                                    <TextField
                                                        id="days-after-retro-to-send-message"
                                                        variant="outlined"
                                                        type="number"
                                                        // label="Days After Retro"
                                                        style={{width: "60px", fontWeight: "bold"}}
                                                        value={daysAfterRetroToSendMessage}
                                                        onChange={(e) => changeDaysAfterRetroToSendMessage(e.target.value)}
                                                        autoFocus
                                                    /> <b style={{color: "#2196f3"}}>days</b> after my last retro.
                                                </Grid>
                                                {didDaysAfterRetroToSendMessageChange &&
                                                    <Button
                                                        color="primary"
                                                        size="large"
                                                        variant="contained"
                                                        disableElevation
                                                        style={{marginTop: "10px"}}
                                                        type="submit">
                                                        Save
                                                    </Button>
                                                }
                                            </form>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <Grid container
                                                  direction="column"
                                                  justify="center"
                                                  alignItems="center"
                                            >
                                                <Button
                                                    style={{width: "220px"}}
                                                    variant="contained"
                                                    onClick={sendTestMessage}
                                                    color="primary"
                                                    size="large"
                                                    disabled={isSendingTestMessage}
                                                    type="submit">
                                                    {sendTestMessageButton}
                                                </Button>
                                                <Button
                                                    onClick={deleteIntegration}
                                                    color="secondary"
                                                    size="large"
                                                    type="submit">
                                                    Disable Integration
                                                </Button>
                                            </Grid>
                                        </div>
                                    }
                                    {!isSlackSetUp && !integrationError &&
                                    <div>
                                        <p>Our Slack integration can keep your team's action items top of mind. We'll remind your team of incomplete action items in your team's Slack channel.</p>
                                        <p>Just click the button below to get started.</p>
                                        <a href="https://slack.com/oauth/v2/authorize?client_id=1253907749922.1239174479095&scope=incoming-webhook&user_scope=" style={{display: "flex", justifyContent: "center"}}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
                                    </div>
                                    }

                                    {integrationError &&
                                        <div>
                                            <Typography color="textSecondary">Looks like something went wrong when trying to connect to Slack. Please try adding Realtime Retro to Slack again.</Typography>
                                            <br />
                                            <a href="https://slack.com/oauth/v2/authorize?client_id=1253907749922.1239174479095&scope=incoming-webhook&user_scope=" style={{display: "flex", justifyContent: "center"}}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
                                        </div>
                                    }
                                    <br/>
                                </Grid>
                            </Paper>
                        </div>
                    </Grid>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state, ownProps) => {
    const integrationSlack = state.firestore.data.integrationSlack;
    return {
        integrationSlack: integrationSlack && integrationSlack[ownProps.teamId],
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addSlackCode: (code) => {
            return dispatch(storeSlackCode(ownProps.teamId, code))
        },

        updateDaysAfterRetroToSendMessage: (days) => {
            return dispatch(slackUpdateDaysAfterRetroToSendMessage(ownProps.teamId, days));
        },

        updateIsActive: (isActive) => {
            return dispatch(slackUpdateIsActive(ownProps.teamId, isActive));
        },

        sendTestMessage: () => {
            return dispatch(slackUpdateSendTestMessage(ownProps.teamId, true));
        }
    }
};

const SlackContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Slack);
export default SlackContainer