import { connect } from 'react-redux'
import {boardColumnCreate, boardColumnToggleShowCreateForm} from "../redux/actions";
import CreateBoardColumn from '../components/CreateBoardColumn'

const mapStateToProps = (state, ownProps) => {
    return {
        boardColumnShowCreateForm: state.column.boardColumnShowCreateForm
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickToggleDisplayForm: (shouldShowForm) => {
            dispatch(boardColumnToggleShowCreateForm(shouldShowForm))
        },
        onClickBoardColumnCreate: (columnName, boardId) => {
            const sortOrder = ownProps.totalColumns ? ownProps.totalColumns : 0;
            return dispatch(boardColumnCreate(ownProps.teamId, columnName, boardId, sortOrder));
        },
    }
};

const CreateBoardColumnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateBoardColumn);
export default CreateBoardColumnContainer