import { connect } from 'react-redux'
import {
    userSetActionItemsFilter
} from "../redux/actions";
import ActionItemsColumn from "../components/ActionItemsColumn";

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        saveActionItemsFilter: (userId, actionItemsFilter) => {
            dispatch(userSetActionItemsFilter(userId, actionItemsFilter));
        },
    }
};

const ActionItemsColumnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionItemsColumn);
export default ActionItemsColumnContainer