import React from 'react'
import {compose} from "redux";
import {firestoreConnect, isLoaded, populate} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {groupBy} from "../../utilities/ArrayUtilities";

function ActiveTeamsMetric({teams, boards, users}) {
    if (!isLoaded(teams, boards, users)) {
        return null;
    }

    const testUsers = groupBy(users.filter(user => {
       return user.email.includes('test');
    }), 'id');

    const boardsByTeamId = groupBy(boards, 'teamId');

    const sortedTeamsByCreatedAt = teams.filter(team => {
      return !testUsers[team.createdBy] ?? true;
    }).map(team => {
        if (typeof boardsByTeamId[team.id] === 'undefined')
        {
            team.lastBoardCreationDate = null;
            return team;
        }

        // TODO: Add logic to set last board creation date
        let lastCreatedBoard = boardsByTeamId[team.id].reduce(((mostRecentCreatedAt, board) => {
            if (!mostRecentCreatedAt) {
                return board;
            }

            return board.createdAt.toDate().getTime() > mostRecentCreatedAt.createdAt.toDate().getTime() ? board : mostRecentCreatedAt;
        }));

        team.lastBoardCreationDate = lastCreatedBoard.createdAt.toDate();
        return team;
    }).sort((element1, element2) => {
        if (element1.lastBoardCreationDate === null) { return 1; }
        if (element2.lastBoardCreationDate === null) { return -1; }

        return element1.lastBoardCreationDate.getTime() > element2.lastBoardCreationDate.getTime() ? -1 : 1;
    });

    return (
        <div>
            <h3 style={{marginLeft: '5px'}}>Active Teams</h3>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Recent Board Creation</strong></TableCell>
                            <TableCell><strong>Team Name</strong></TableCell>
                            <TableCell><strong>Created At</strong></TableCell>
                            <TableCell><strong>Total Boards</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedTeamsByCreatedAt.map((team) => (
                            <TableRow key={team.id}>
                                <TableCell>{team.lastBoardCreationDate === null ? 'None' : team.lastBoardCreationDate.toLocaleString()}</TableCell>
                                <TableCell component="th" scope="row">
                                    {team.name}
                                </TableCell>
                                <TableCell>{team.createdAt.toDate().toLocaleString()}</TableCell>
                                <TableCell>{boardsByTeamId[team.id] ? boardsByTeamId[team.id].length : 0}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        users: state.firestore.ordered.allUsers,
        teams: state.firestore.ordered.allTeams,
        boards: state.firestore.ordered.allBoards
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const currentUser = state.firebase.auth().currentUser;

        // Check for super admin to make sure we don't show insufficient privilege errors for our non-admin users
        if (currentUser.email !== "oalbrecht@gmail.com") {
            return [];
        }

        return [
            {
                collection: 'teams',
                storeAs: 'allTeams'
            },
            {
                collection: 'boards',
                storeAs: 'allBoards'
            },
            {
                collection: 'users',
                storeAs: 'allUsers'
            },
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ActiveTeamsMetric)
