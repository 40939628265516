import { connect } from 'react-redux'
import {feedbackUpdate, feedbackHeartToggle, feedbackToggleIsEditing, feedbackDelete} from "../redux/actions";
import Feedback from '../components/Feedback'

const mapStateToProps = (state, ownProps) => {
    return {
        isEditing: state.feedback.isEditing[ownProps.feedbackId]
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickFeedbackHeartToggle: () => {
            // If hearting isn't allowed, only allow hearts to be removed, not added
            if (ownProps.allowHearting === false && ownProps.isHearted === false) return;

            dispatch(feedbackHeartToggle(ownProps.teamId, ownProps.boardId, ownProps.feedbackId, !ownProps.isHearted))
        },
        onClickFeedbackUpdate: (feedbackText, displayName) => {
            dispatch(feedbackUpdate(ownProps.feedbackId, feedbackText))
        },
        onClickToggleIsEditing: (isEditing) => {
            dispatch(feedbackToggleIsEditing(isEditing, ownProps.feedbackId))
        },
        onClickDelete: () => {
            dispatch(feedbackDelete(ownProps.feedbackId))
        },
    }
};

const FeedbackContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Feedback);
export default FeedbackContainer