import React, {useState} from 'react'
import './BoardColumn.css'
import onBlur from '../utilities/Blur';
import TextField from "@material-ui/core/TextField/TextField";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import DeleteModal from "./DeleteModal";

const BoardColumn =({children, name, isEditing, isLastColumn, boardColumnShowCreateForm,
                        onClickDoneEditing, onClickToggleIsEditing, onClickDelete, onClickBoardColumnToggleShowCreateForm
}) => {
    const [columnName, changeColumnName] = useState(name);
    isEditing = isEditing || isLastColumn && boardColumnShowCreateForm;

    const setColumnName = () => {
        onClickBoardColumnToggleShowCreateForm(false);
        onClickDoneEditing(columnName);
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setColumnName();
        }
    };

    return (
        <div className="BoardColumn" onBlur={(e) => onBlur(e, () => setColumnName())} >
            {isEditing &&
                <form onSubmit={(e) => { e.preventDefault(); onClickDoneEditing(columnName);}}>
                    <h2 className="BoardColumnEdit"><TextField value={columnName} autoFocus placeholder="Column name..." onChange={(e) => changeColumnName(e.target.value)} id="outlined-basic" size="small" variant="outlined" onKeyPress={(ev) => {handleKeypress(ev)}} /></h2>
                </form>
            }
            {!isEditing &&
                <div className="BoardColumnNameWrapper">
                    <DeleteModal onClickDelete={onClickDelete} recordName='column'>
                        <IconButton aria-label="edit" color="secondary" className="BoardColumnDelete">
                            <Icon>delete</Icon>
                        </IconButton>
                    </DeleteModal>
                    <h3 className="BoardColumnName" onClick={() => onClickToggleIsEditing(true)}>
                        {name}
                    </h3>
                </div>
            }
            <div className="BoardColumnChildren">
                {children}
            </div>
        </div>
    )
};

export default BoardColumn
