import { connect } from 'react-redux'
import TeamForm from "../components/TeamForm";
import {teamDelete, teamUserDelete, updateTeam} from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickDoneEditing: (teamName) => {
            return dispatch(updateTeam(ownProps.teamId, teamName));
        },
        onClickTeamUserDelete: (teamUserId) => {
            return dispatch(teamUserDelete(teamUserId));
        },
        onClickTeamDelete: (id) => {
            return dispatch(teamDelete(ownProps.teamId, ownProps.teamUsers));
        }
    }
};

const TeamFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamForm);
export default TeamFormContainer