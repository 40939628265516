import React, {useEffect} from 'react'
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Group from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Icon from "@material-ui/core/Icon";
import {useHistory} from 'react-router-dom';
import './UserForm.css';
import BackButton from "./BackButton";

const UserForm =({userId, email, displayName, onClickUpdate}) => {
    const [updatedUserName, setUpdatedUserName] = React.useState(displayName);
    const [updatedEmail, setUpdatedEmail] = React.useState(email);

    function update(userId, updatedUserName, updatedEmail) {
        onClickUpdate(userId, updatedUserName, updatedEmail).then(() => {
            history.push('/board');
        });
    }

    const history = useHistory();
    return (
        <div>
            <BackButton/>
            <div className="UserForm">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <div style={{maxWidth: 800}}>
                        <Paper className="UserFormPaper" elevation={2}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                            >
                                <Avatar color="primary">
                                    <Icon>person</Icon>
                                </Avatar>
                                <div className="TeamFormMargin">
                                    <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
                                        User Setup
                                    </Typography>
                                </div>
                                <form style={{minWidth: 250}} onSubmit={(e) => {update(userId, updatedUserName, updatedEmail); e.preventDefault();}}>
                                    <Grid item xs={12} md={12}>
                                        <br />
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            placeholder="Full Name"
                                            label="Full Name"
                                            fullWidth
                                            value={updatedUserName}
                                            onChange={(e) => setUpdatedUserName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <br />
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            placeholder="Email"
                                            label="Email"
                                            fullWidth
                                            value={updatedEmail}
                                            onChange={(e) => setUpdatedEmail(e.target.value)}
                                        />
                                    </Grid>
                                    <Button
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        disableElevation
                                        style={{marginTop: "10px"}}
                                        type="submit">
                                        Save
                                    </Button>
                                </form>
                                <br/>
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
            </div>
        </div>
    )
};

export default UserForm;

