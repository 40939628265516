import { connect } from 'react-redux'
import { teamCreate, setTeamIsCreating} from "../redux/actions";
import CreateTeam from "../components/CreateTeam";

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.firestore.data.user
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickCreateTeam: (teamName, email, displayName) => {
            return dispatch(teamCreate(teamName, email, displayName)).then(() => {
                dispatch(setTeamIsCreating(false));
            });
        }
    }
};

const CreateFeedbackContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTeam);
export default CreateFeedbackContainer