import { connect } from 'react-redux'
import {actionItemCreate} from "../redux/actions";
import CreateFeedback from '../components/CreateFeedback'
import CreateActionItem from "../components/CreateActionItem";

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.firestore.data.userTeamView
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickActionItemCreate: (text, displayName) => {
            dispatch(actionItemCreate(ownProps.teamId, text, ownProps.boardId, displayName))
        }
    }
};

const CreateFeedbackContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateActionItem);
export default CreateFeedbackContainer