import React from 'react';
import UserFirestoreContainer from "../firestoreContainers/UserFirestoreContainer";
import NavBarContainer from "../containers/NavBarContainer";
import CurrentTeamFirestoreContainer from '../firestoreContainers/CurrentTeamFirestoreContainer'

const UserPage = () => {
    return (
        <div>
            <CurrentTeamFirestoreContainer>
                <NavBarContainer/>
                <UserFirestoreContainer/>
            </CurrentTeamFirestoreContainer>
        </div>
    )
};

export default UserPage;
