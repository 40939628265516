import React from 'react'
import '../App.css'
import BoardsFirestoreContainerContainer from "../containers/BoardsFirestoreContainerContainer";
import CurrentTeamFirestoreContainer from "../firestoreContainers/CurrentTeamFirestoreContainer";
import NavBarContainer from "../containers/NavBarContainer";
import ShareDialog from "../components/ShareDialog";
import {isMobileOnly} from 'react-device-detect';
import MobileWarning from '../components/MobileWarning';

const BoardPage = () => {
    if (isMobileOnly) {
        return <MobileWarning/>;
    }

    return (
        <CurrentTeamFirestoreContainer>
            <NavBarContainer/>
            <BoardsFirestoreContainerContainer/>
            <ShareDialog/>
        </CurrentTeamFirestoreContainer>
  )
};

export default BoardPage;
