import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button/Button";
import React from "react";
import "./BackButton.css";
import {useHistory} from 'react-router-dom';

const BackButton = () => {
    const history = useHistory();
    return (
        <Button color="primary" className="BackButton" onClick={() => history.push('/board')}><ChevronLeftIcon /> Back</Button>
    )
};

export default BackButton;
