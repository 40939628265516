import React from 'react';
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles({
    root: {
        maxWidth: 250,
        margin: '10px',
    },
    media: {
        height: 50,
        objectFit: 'contain'
    },
    actionArea: {
        minHeight: '250px'
    }
});

const BoardTemplateOption = ({title, image, columnNames}) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardActionArea className={classes.actionArea}>
                <CardMedia
                    className={classes.media}
                    component="img"
                    image={image}
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div">
                        {columnNames !== null &&
                            <ul>
                                {columnNames.map((columnName, index) => (
                                    <li key={index}>{columnName}</li>
                                ))}
                            </ul>
                        }
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default BoardTemplateOption;