import React from 'react'
import '../App.css'
import {compose} from "redux";
import {firestoreConnect, isLoaded, populate} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {setAdminImpersonationUser} from "../redux/actions";
import LocalStorageUtility from '../utilities/LocalStorageUtility';

function getDisplayName(allUsers, impersonationUserId) {
    if (!impersonationUserId) return '';

    const filteredUsers = allUsers.filter((user) => user.id === impersonationUserId);
    if (filteredUsers.length === 0) return '';
    return filteredUsers[0].displayName;
}

function AdminUserPicker({currentUser, allUsers,
                             setAdminImpersonationUser}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    if (!isLoaded(allUsers, currentUser) || !allUsers) {
        return null;
    }

    if (allUsers && allUsers.length <= 1) {
        return null;
    }

    const impersonationUserId = LocalStorageUtility().getAdminImpersonationUserId();

    const displayName = impersonationUserId !== null ? getDisplayName(allUsers, impersonationUserId) : currentUser.displayName;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (userId) => {
        if (typeof userId == 'string') {
            setAdminImpersonationUser(userId);
            window.location.reload();
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" style={{color: 'yellow'}} onClick={handleClick}>
                { displayName || ''}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {allUsers.map((user) => (
                    <span key={user.id}>
                        <MenuItem onClick={() => handleClose(user.id)}>{user.displayName} | {user.email} | {user.id}</MenuItem>
                    </span>
                ))}
            </Menu>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.firestore.data.user,
        allUsers: state.firestore.ordered.adminAllUsers,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setAdminImpersonationUser: (userId) => {
            return dispatch(setAdminImpersonationUser(userId));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const currentUser = state.firebase.auth().currentUser;

        // Check for super admin to make sure we don't show insufficient privilege errors for our non-admin users
        if (currentUser.email !== "oalbrecht@gmail.com") {
            return [];
        }

        return [
            {
                collection: 'users',
                storeAs: 'adminAllUsers'
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AdminUserPicker)
