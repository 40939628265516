import { connect } from 'react-redux'
import {
    boardUpdate,
    boardToggleIsEditing,
    boardDelete,
    boardSetViewId
} from "../redux/actions";
import Board from '../components/Board'

const mapStateToProps = (state, ownProps) => {
    return {
        orderedBoards: state.firestore.ordered.boards,
        currentBoardIndex: state.board.currentBoardIndex,
        isEditing: state.board.isEditing
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickDoneEditing: (name) => {
            dispatch(boardUpdate(ownProps.boardId, name))
        },
        onClickToggleIsEditing: (isEditing) => {
            dispatch(boardToggleIsEditing(isEditing))
        },
        onClickDelete: () => {
            dispatch(boardDelete(ownProps.boardId))
        },
        onClickNextBoard: (orderedBoards, currentBoardId) => {
            const indexOfCurrentBoard = orderedBoards.map(board => board.id).indexOf(currentBoardId);
            const nextBoardIndex = indexOfCurrentBoard - 1;

            if (nextBoardIndex >= 0) {
                dispatch(boardSetViewId(orderedBoards[nextBoardIndex].id));
            }
        },
        onClickPreviousBoard: (orderedBoards, currentBoardId) => {
            const indexOfCurrentBoard = orderedBoards.map(board => board.id).indexOf(currentBoardId);
            const previousBoardIndex = indexOfCurrentBoard + 1;

            if (previousBoardIndex < orderedBoards.length) {
                dispatch(boardSetViewId(orderedBoards[previousBoardIndex].id));
            }
        },
    }
};

const BoardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Board);
export default BoardContainer