import React from 'react'
import '../App.css'
import {compose} from "redux";
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import { useHistory } from "react-router-dom";
import Loading from "../components/Loading";
import LocalStorageUtility from '../utilities/LocalStorageUtility';
import {getUserId} from "../utilities/UserUtility";
import {teamCreate, userSetHasClearedDemo} from "../redux/actions";
import {setInitialUserData, userSetHasViewedDemo} from "../redux/actions";

function SignInRedirectFirestoreContainer({teamUsers, user,
                                            handleCreateTeam, updateUserWithUserAgent, updateUserWithHasViewedDemo
}) {
    let history = useHistory();
    if (!isLoaded(teamUsers, user)) {
        return <Loading/>;
    }

    if (teamUsers === null || teamUsers.length === 0) {
        handleCreateTeam('My Team', user.email, user.displayName).then(() => {
            updateUserWithUserAgent(user.id);
            updateUserWithHasViewedDemo(user.id);

            // Add a localstorage key here to show share recommendation modal.
            LocalStorageUtility().setShowShareTeam('true');

            history.push('/initialTeamSetup');
        });

    } else {
        history.push('/board');
    }
    return null;
}

const mapStateToProps = (state, ownProps) => {
    const users = state.firestore.ordered.users;
    return {
        user: users && users.length > 0 && users[0],
        teamUsers: state.firestore.ordered.teamUsers
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleCreateTeam: (name, email, displayName) => {
            return dispatch(teamCreate(name, email, displayName));
        },
        updateUserWithUserAgent: (userId) => {
            dispatch(setInitialUserData(userId));
        },
        updateUserWithHasViewedDemo: (userId) => {
            dispatch(userSetHasViewedDemo(userId, false));
            dispatch(userSetHasClearedDemo(userId, false));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const userId = getUserId(state.firebase);
        return [
            {   // Necessary for creation of records, which require a team set for the user
                collection: 'users',
                doc: userId
            },
            {
                collection: 'teamUsers',
                where: [
                    ['userId', '==', userId]
                ]
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SignInRedirectFirestoreContainer)
