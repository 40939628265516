import { connect } from 'react-redux'
import {commentHeartToggle, commentToggleIsEditing, commentUpdate, commentDelete} from "../redux/actions";
import Comment from '../components/Comment'

const mapStateToProps = (state, ownProps) => {
    return {
        isEditing: state.comment.isEditing[ownProps.commentId]
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickCommentHeartToggle: () => {
            dispatch(commentHeartToggle(ownProps.teamId, ownProps.boardId, ownProps.commentId, !ownProps.isHearted))
        },
        onClickToggleIsEditing: (isEditing) => {
            dispatch(commentToggleIsEditing(isEditing, ownProps.commentId))
        },
        onClickCommentUpdate: (text) => {
            dispatch(commentUpdate(ownProps.commentId, text));
        },
        onClickDelete: () => {
            dispatch(commentDelete(ownProps.commentId))
        },
    }
};

const CommentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Comment);
export default CommentContainer