import React from 'react'
import CurrentTeamFirestoreContainer from "../../firestoreContainers/CurrentTeamFirestoreContainer";
import NavBarContainer from "../../containers/NavBarContainer";
import AdminMetrics from "../../components/admin/AdminMetrics";

const AdminMetricsPage = () => {
    return (
        <CurrentTeamFirestoreContainer>
            <NavBarContainer/>
            <AdminMetrics/>
        </CurrentTeamFirestoreContainer>
    )
};

export default AdminMetricsPage;
