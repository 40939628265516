import React from 'react'
import {compose} from "redux";
import {firestoreConnect, isLoaded, populate} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {groupBy} from "../../utilities/ArrayUtilities";

function UserMetric({users, feedback}) {
    if (!isLoaded(users, feedback)) {
        return null;
    }

    const usersWithCreatedAt = users.filter((user) => {
        return user.createdAt && !user.email.includes('test');
    }).sort((element1, element2) => {
       return element1.createdAt.toDate().getTime() > element2.createdAt.toDate().getTime() ? -1 : 1;
    });

    const feedbackByUserId = groupBy(feedback, 'createdBy');

    return (
        <div>
            <h3 style={{marginLeft: '5px'}}>Recently Created Users</h3>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Email</strong></TableCell>
                            <TableCell><strong>Created At</strong></TableCell>
                            <TableCell><strong>Total Feedback Given</strong></TableCell>
                            <TableCell><strong>IsMobile</strong></TableCell>
                            <TableCell><strong>Mobile Vendor</strong></TableCell>
                            <TableCell><strong>Is Tablet</strong></TableCell>
                            <TableCell><strong>Browser</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersWithCreatedAt.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell component="th" scope="row">
                                    {user.displayName}
                                </TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.createdAt.toDate().toLocaleString()}</TableCell>
                                <TableCell>{feedbackByUserId[user.id] ? feedbackByUserId[user.id].length : 0}</TableCell>
                                <TableCell>{user.isMobile}</TableCell>
                                <TableCell>{user.mobileVendor}</TableCell>
                                <TableCell>{user.isTablet}</TableCell>
                                <TableCell>{user.browserName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        users: state.firestore.ordered.allUsers,
        feedback: state.firestore.ordered.allFeedback
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const currentUser = state.firebase.auth().currentUser;

        // Check for super admin to make sure we don't show insufficient privilege errors for our non-admin users
        if (currentUser.email !== "oalbrecht@gmail.com") {
            return [];
        }

        return [
            {
                collection: 'users',
                storeAs: 'allUsers'
            },
            {
                collection: 'feedback',
                storeAs: 'allFeedback'
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UserMetric)
