import React, {useState} from 'react'
import CardContent from "@material-ui/core/CardContent/CardContent";
import Card from "@material-ui/core/Card/Card";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import './CreateFeedback.css'
import onBlur from '../utilities/Blur'

const CreateActionItem = ({currentUser, onClickActionItemCreate}) => {
    const [text, setText] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const submitPressed = () => {
        if (text.trim().length === 0) {
            return;
        }

        onClickActionItemCreate(text, currentUser.displayName);
        setText('');
    };

    const handleKeypress = (e) => {
        if (e.ctrlKey) {
            setText(text + '\n');
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            submitPressed();
        }
    };

    return (
        <div onBlur={(e) => {onBlur(e, () => {setIsEditing(false);})}}>
            <Card className="CreateFeedback">
                <CardContent>
                    <TextField
                        id="standard-multiline-flexible"
                        variant="outlined"
                        placeholder="Add your action item..."
                        multiline
                        fullWidth
                        rowsMax="10"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        onFocus={(e) => setIsEditing(true)}
                        onKeyPress={(ev) => {handleKeypress(ev)}}
                    />
                    {isEditing &&
                    <div className="FeedbackButtonsContainer">
                        <div className="FeedbackButtons">
                            <Button position="right" className="SubmitFeedbackButton" variant="contained"
                                    color="primary"
                                    onClick={submitPressed}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                    }
                </CardContent>
            </Card>
        </div>
    );
};

export default CreateActionItem;