import React from 'react'
import BoardPage from "../pages/BoardPage";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SignInPage from "../pages/SignInPage";
import TeamPage from '../pages/TeamPage'
import SharePage from '../pages/SharePage'
import PrivateRoute from './PrivateRoute'
import AuthIsLoaded from './AuthIsLoaded'
import SharedRedirectFirestoreContainer from "../firestoreContainers/SharedRedirectFirestoreContainer";
import MarketingPage from "../pages/MarketingPage";
import UserPage from "../pages/UserPage";
import InitialTeamSetupPage from "../pages/InitialTeamSetupPage";
import HelpPage from "../pages/HelpPage";
import SignInRedirectFirestoreContainer from "../firestoreContainers/SignInRedirectFirestoreContainer";
import AdminMetricsPage from "../pages/admin/AdminMetricsPage";
import SlackPage from "../pages/integrations/SlackPage";

const Router = () => {
    return (
        <BrowserRouter>
            <AuthIsLoaded>
                <Switch>
                    <Route exact path="/" component={MarketingPage}/>
                    <Route path="/signIn/:token?" component={SignInPage}/>
                    <PrivateRoute path="/admin/metrics" component={AdminMetricsPage}/>
                    <PrivateRoute path="/board">
                        <BoardPage />
                    </PrivateRoute>
                    <PrivateRoute path="/initialTeamSetup">
                        <InitialTeamSetupPage />
                    </PrivateRoute>
                    <PrivateRoute path="/help">
                        <HelpPage />
                    </PrivateRoute>
                    <PrivateRoute path="/integrations/slack" component={SlackPage}/>
                    <PrivateRoute path="/team">
                        <TeamPage />
                    </PrivateRoute>
                    <PrivateRoute path="/user">
                        <UserPage />
                    </PrivateRoute>
                    <PrivateRoute path="/share">
                        <SharePage />
                    </PrivateRoute>
                    <PrivateRoute path="/redirect" component={SignInRedirectFirestoreContainer} />
                    <PrivateRoute path="/sharedRedirect/:token?" component={SharedRedirectFirestoreContainer} />
                </Switch>
            </AuthIsLoaded>
        </BrowserRouter>
    )
};

export default Router;