import React from "react";
import CreateTeam from "./CreateTeam";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import Icon from "@material-ui/core/Icon/Icon";
import { useHistory } from "react-router-dom";
import './InitialTeamSetup.css';

const InitialTeamSetup = ({user,
                              onClickSetTeamName}) => {
    const history = useHistory();

    const setTeam = (name) => {
        onClickSetTeamName(name).then(() => {;
            history.push('/board');
        });
    };

    return (
        <div className="InitialTeamSetupForm">
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <div style={{width: 350}}>
                    <Paper className="InitialTeamSetupFormPaper" elevation={2}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Avatar color="primary">
                                <Icon>people</Icon>
                            </Avatar>
                            <div className="TeamFormMargin">
                                <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
                                    Team Setup
                                </Typography>
                            </div>
                            <CreateTeam onClickCreateTeam={setTeam} user={user}/>
                            <br/>
                        </Grid>
                    </Paper>
                </div>
            </Grid>
        </div>
    );
};

export default InitialTeamSetup;