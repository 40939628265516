import React from "react";
import Paper from "@material-ui/core/Paper";
import "./Help.css";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Icon from "@material-ui/core/Icon/Icon";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import BackButton from "./BackButton";

const Help = () => {
    return (
        <div>
            <BackButton/>
            <Paper className="HelpPaper" elevation={2}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Avatar color="primary">
                        <Icon>help</Icon>
                    </Avatar>
                    <span className="HelpBody">
                        <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
                            Need help or have feedback?
                        </Typography>
                        <hr style={{border: '1px solid #f3f3f3'}}/>
                        <span style={{color: "gray"}}>
                             <p>
                                For help, please email us at <strong>support@realtimeretro.com</strong>. We will do our best to help you resolve whatever issue you're experiencing.
                            </p>
                            <p>
                                If you have any feedback on how to improve Realtime Retro, we would love to hear from you! Please email us at <strong>feedback@realtimeretro.com</strong>.
                            </p>
                        </span>
                    </span>
                </Grid>
            </Paper>
        </div>
    )
};

export default Help;
