import React from "react";
import "./ActionItemsColumn.css";
import {ButtonGroup, Button} from "@material-ui/core";
import CreateActionItemContainer from "../containers/CreateActionItemContainer";
import FadeIn from "react-fade-in";
import ActionItemContainer from "../containers/ActionItemContainer";
import {getAvatarColor} from "../utilities/ColorUtility";

function isOutstandingActionItem(actionItem, board) {
    return actionItem.boardId !== board.id && actionItem.createdAt.seconds < board.createdAt.seconds;
}

function shouldShowActionItem(actionItemFilter, actionItem, board) {
    const isOutstanding = isOutstandingActionItem(actionItem, board);
    const isCurrentBoardActionItem = actionItem.boardId === board.id;

    if (actionItemFilter === 'outstanding') {
        return isOutstanding;
    }

    if (actionItemFilter === 'new') {
        return isCurrentBoardActionItem;
    }

   return isOutstanding || isCurrentBoardActionItem;
}

function filterActionItems(actionItemFilter, actionItems, board) {
    return actionItems.filter((actionItem) => {
        return shouldShowActionItem(actionItemFilter, actionItem, board)
    });
}

const ActionItemsColumn = ({children, name, teamId, user, userId, board, actionItems,
                               saveActionItemsFilter}) => {
    const [actionItemFilter, setActionItemFilter] = React.useState(user.actionItemsFilter ?? 'new');

    const setAndSaveActionItemsFilter = (actionItemsFilter) => {
        setActionItemFilter(actionItemsFilter);
        saveActionItemsFilter(userId, actionItemsFilter);
    };

    let filteredActionItems = filterActionItems(actionItemFilter, actionItems, board);

    return (
        <div className="BoardColumn ActionItemsColumn">
            <div className="BoardColumnNameWrapper">
                <h3 className="ActionItemsColumnName">
                    {name}
                </h3>
            </div>
            <div className="BoardColumnChildren">
                <div style={{display: "flex", justifyContent: "center", paddingBottom: "10px"}}>
                    <ButtonGroup color="primary" aria-label="outlined primary button group" style={{backgroundColor: "white"}}>
                        <Button variant={actionItemFilter === "new" ? "contained" : "outlined"} onClick={() => setAndSaveActionItemsFilter('new')}>New</Button>
                        <Button variant={actionItemFilter === "outstanding" ? "contained" : "outlined"} onClick={() => setAndSaveActionItemsFilter('outstanding')}>Outstanding</Button>
                        <Button variant={actionItemFilter === "all" ? "contained" : "outlined"} onClick={() => setAndSaveActionItemsFilter('all')}>All</Button>
                    </ButtonGroup>
                </div>
                {actionItemFilter !== 'outstanding' &&
                    <CreateActionItemContainer teamId={teamId} boardId={board.id}/>
                }
                {filteredActionItems.length === 0 && actionItemFilter === "outstanding" &&
                    <div style={{textAlign: "center", color: "#757575"}}>There are no outstanding action items.</div>
                }
                {filteredActionItems.map(actionItem => (
                    <div key={actionItem.id}>
                        {/*{shouldShowActionItem(actionItemFilter, actionItem, board) &&*/}
                            <FadeIn key={actionItem.id}>
                                <ActionItemContainer
                                    boardId={board.id}
                                    actionItem={actionItem}
                                    teamId={teamId}
                                    isOutstandingActionItem={isOutstandingActionItem(actionItem, board)}
                                    avatarColor={getAvatarColor(actionItem.userName)}
                                />
                            </FadeIn>
                        {/*}*/}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default ActionItemsColumn;