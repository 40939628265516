import { connect } from 'react-redux'
import {commentCreate} from "../redux/actions";
import CreateComment from '../components/CreateComment'

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.firestore.data.userTeamView
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickCommentCreate: (commentText, userDisplayName) => {
            dispatch(commentCreate(ownProps.teamId, commentText, ownProps.feedbackId, ownProps.boardId, userDisplayName))
        },
    }
};

const CreateCommentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateComment);
export default CreateCommentContainer