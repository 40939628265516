import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from "@material-ui/core/Icon";
import TeamPickerFirestoreContainer from "../firestoreContainers/TeamPickerFirestoreContainer";
import { useHistory } from "react-router-dom";
import logo from '../images/logo-new-white.svg';
import AdminUserPicker from "../firestoreContainers/AdminUserPicker";
import slackImage from "../images/slack.png";
import Group from "@material-ui/icons/Group";
import Avatar from "@material-ui/core/Avatar/Avatar";
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const NavBar = ({teamId,
                    onClickLogout
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [isShowingSettings, setIsShowingSettings] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToBoard = () => {
      history.push('/board');
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" onClick={redirectToBoard} className={classes.menuButton} color="inherit" aria-label="menu">
                    <img src={logo} height="25px"></img>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    <span onClick={redirectToBoard} style={{cursor: "pointer"}}>Realtime Retro</span>
                </Typography>
                <AdminUserPicker/>
                <TeamPickerFirestoreContainer id={teamId}/>
                <Tooltip title="Share retro board" aria-label="share">
                    <IconButton aria-label="share" color="inherit" onClick={() => { history.push('/share'); }}>
                        <Icon>share</Icon>
                    </IconButton>
                </Tooltip>
                <div>
                    <Tooltip title="Settings" aria-label="settings">
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Icon>settings</Icon>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => {handleClose(); history.push('/team');}}>
                            <Group style={{color: "#2196f3"}} />&nbsp; Team Setup
                        </MenuItem>
                        <MenuItem onClick={() => {handleClose(); history.push('/user');}}>
                            <Icon style={{color: "#2196f3"}}>person</Icon>&nbsp; User Setup
                        </MenuItem>
                        <MenuItem onClick={() => {handleClose(); history.push('/integrations/slack');}}><img src={slackImage} height="33px" style={{marginLeft: "-5px"}}></img>&nbsp;Slack Integration</MenuItem>
                    </Menu>
                </div>
                <Tooltip title="Request a new feature" aria-label="features">
                    <IconButton aria-label="share" color="inherit" onClick={() => { document.location.href = 'https://feedback.userreport.com/e904b91e-f02d-46e9-ac28-d10ba12c7d0d/#submit/idea'; }}>
                        <EmojiObjectsIcon/>
                    </IconButton>
                </Tooltip>
                <Button color="inherit" onClick={() => {onClickLogout()}}>Logout</Button>
            </Toolbar>
        </AppBar>
    )
};

export default NavBar;