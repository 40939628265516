import React, {cloneElement} from 'react'
import '../App.css'
import {compose} from "redux";
import {firestoreConnect, isLoaded, isEmpty, populate} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import Loading from "../components/Loading";
import {setTeamCurrentlyViewingTeam} from "../redux/actions";
import Button from "@material-ui/core/Button/Button";
import { useHistory } from "react-router-dom";
import {getUserId} from "../utilities/UserUtility";
import LocalStoragUtility from "../utilities/LocalStorageUtility";

function doesTeamExist(teamUsers, teamId) {
    return teamUsers.filter(teamUser => {
        return teamUser.teamId === teamId;
    }).length >= 1;
}

const getDefaultTeamId = (teamUsers) => {
    const firstTeamId = teamUsers[0].teamId;
    const localStorageTeamId = LocalStoragUtility().getCurrentlyViewingTeamId();

    // If no local storage team value, return first team
    if (localStorageTeamId === null) {
        return firstTeamId;
    }

    // If local storage team no longer exists, return the first team
    if (!doesTeamExist(teamUsers, localStorageTeamId)) {
        return firstTeamId;
    }

    return localStorageTeamId;
};


function CurrentTeamFirestoreContainer({children, userTeamView, teamUsers, currentlyViewingTeamId,
                                           setCurrentlyViewingTeam
}) {
    const history = useHistory();
    const [showCreateTeam, setShowCreateTeam] = React.useState(false);

    if (!isLoaded(userTeamView, teamUsers)) {
        return <Loading/>;
    }

    if (isEmpty(userTeamView, teamUsers)) {
        // Add a setTimeout to prevent this page from flickering on when someone
        // first signs up.
        setTimeout(() => {
            setShowCreateTeam(true);
        }, 1500);

        return (
            <div style={{textAlign: "center", color:"rgb(123, 123, 123)"}}>
                {showCreateTeam &&
                    <>
                        <h2>Looks like you're currently not a part of a team. :(</h2>
                        <p>Click the button below to create a new one, or have one of your team members share an existing on with you.</p>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => {
                                history.push('/redirect');
                            }}
                        >
                            Create a Team
                        </Button>
                    </>
                }
            </div>
        );
    }

    const teamId = currentlyViewingTeamId === null ? getDefaultTeamId(teamUsers) : currentlyViewingTeamId;

    // Check to make sure team exists. It may have been deleted.
    if (!doesTeamExist(teamUsers, teamId)) {
        setCurrentlyViewingTeam(teamUsers[0].teamId);
    }

    return (
        <div>
            {Array.isArray(children) ? children.map((child, index) => (
                <div key={index}>
                    {cloneElement(child, { teamId })}
                </div>
            )) :
                <div>
                    {cloneElement(children, { teamId})}
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        userTeamView: state.firestore.data.userTeamView,
        teamUsers: state.firestore.ordered.teamUsers,
        currentlyViewingTeamId: state.team.currentlyViewingTeamId,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setCurrentlyViewingTeam: (teamId) => {
            return dispatch(setTeamCurrentlyViewingTeam(teamId));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const userId = getUserId(state.firebase);
        return [
            { // This is not used in this component, but other components need this query to be executed
                collection: 'users',
                doc: userId,
                storeAs : 'userTeamView'
            },
            {
                collection: 'teamUsers',
                where: [
                    ['userId', '==', userId],
                ]
            },
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CurrentTeamFirestoreContainer)
