import { connect } from 'react-redux'
import {
    boardColumnDelete,
    boardColumnUpdate,
    columnToggleIsEditing,
    boardColumnToggleShowCreateForm
} from "../redux/actions";
import BoardColumn from "../components/BoardColumn";

const mapStateToProps = (state, ownProps) => {
    return {
        isEditing: state.column.isEditing[ownProps.columnId],
        boardColumnShowCreateForm: state.column.boardColumnShowCreateForm
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickDoneEditing: (name) => {
            dispatch(boardColumnUpdate(ownProps.columnId, name))
        },
        onClickToggleIsEditing: (isEditing) => {
            dispatch(columnToggleIsEditing(isEditing, ownProps.columnId))
        },
        onClickDelete: () => {
            dispatch(boardColumnDelete(ownProps.columnId, ownProps.sortedColumns));
        },
        onClickBoardColumnToggleShowCreateForm: (shouldShowForm) => {
            dispatch(boardColumnToggleShowCreateForm(shouldShowForm));
        }
    }
};

const BoardColumnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardColumn);
export default BoardColumnContainer