import { connect } from 'react-redux'
import {logout} from "../redux/actions";
import NavBar from '../components/NavBar'
import LocalStorageUtility from "../utilities/LocalStorageUtility";

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickLogout: () => {
            return dispatch(logout()).then(() => {
                LocalStorageUtility().resetAdminImpersonationUserId();
                window.location.reload();
            });
        }
    }
};

const NavBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBar);
export default NavBarContainer