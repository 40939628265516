import React, {useEffect} from 'react';
import {storeSlackCode, slackUpdateDaysAfterRetroToSendMessage, slackUpdateIsActive} from "../redux/actions";
import connect from "react-redux/es/connect/connect";
import { useHistory } from "react-router-dom";
import {compose} from "redux";
import {firestoreConnect, isLoaded, isEmpty} from "react-redux-firebase";
import Slack from '../containers/SlackContainer';
import Loading from "../components/Loading";
import {getUserId} from "../utilities/UserUtility";

const SlackFirestoreContainer = ({code, teamId, integrationSlack,
}) => {
    if (!isLoaded(integrationSlack)) {
        return <Loading/>;
    }

    return (
        <Slack code={code} teamId={teamId} integrationSlack={integrationSlack}/>
    )
};

export default compose(
    firestoreConnect((props, state) => {
        if (!props.teamId) return [];
        return [
            {
                collection: 'integrationSlack',
                doc: props.teamId
            }
        ];
    }),
    connect((state, props) => {
        const integrationSlack = state.firestore.data.integrationSlack;
        return {
            integrationSlack: integrationSlack && integrationSlack[props.teamId],
        }
    })
)(SlackFirestoreContainer)
