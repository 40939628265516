const LocalStorageUtility = () => {
    return {
        setShowShareTeam: (showTeam) => {
            localStorage.setItem('showShareTeam', showTeam);
        },

        getShowShareTeam: () => {
            return localStorage.getItem('showShareTeam');
        },

        setAdminImpersonationUserId: (userId) => {
            localStorage.setItem('impersonationUserId', userId);
        },

        getAdminImpersonationUserId: () => {
            return localStorage.getItem('impersonationUserId');
        },

        resetAdminImpersonationUserId: () => {
            localStorage.removeItem('impersonationUserId');
        },

        setCurrentlyViewingTeamId: (teamId) => {
            localStorage.setItem('currentlyViewingTeamId', teamId);
        },

        getCurrentlyViewingTeamId: () => {
            return localStorage.getItem('currentlyViewingTeamId');
        },
    }
};

export default LocalStorageUtility;