import React from 'react';
import NavBarContainer from "../containers/NavBarContainer";
import CurrentTeamFirestoreContainer from "../firestoreContainers/CurrentTeamFirestoreContainer";
import ShareFirestoreContainer from "../firestoreContainers/ShareFirestoreContainer";

const SharePage = () => {
    return (
        <div>
            <CurrentTeamFirestoreContainer>
                <NavBarContainer/>
                <ShareFirestoreContainer/>
            </CurrentTeamFirestoreContainer>
        </div>
    )
};

export default SharePage;
