import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BoardTemplateOption from "../components/BoardTemplateOption";
import trafficLightImage from '../images/boardTemplates/traffic-light.png';
import originalImage from '../images/boardTemplates/original.png';
import copyImage from '../images/boardTemplates/copy.png';
import madImage from '../images/boardTemplates/mad.png';
import lImage from '../images/boardTemplates/l-2.png';
import starfishImage from '../images/boardTemplates/starfish.png';
import sailboatImage from '../images/boardTemplates/sailboat.png';
import speedCarImage from '../images/boardTemplates/speedcar.png';
import plusImage from '../images/boardTemplates/plus.png';
import strengthImage from '../images/boardTemplates/strength.png';
import duckImage from '../images/boardTemplates/duck.png';
import pigImage from '../images/boardTemplates/pig.png';
import connect from "react-redux/es/connect/connect";
import {boardChooseTemplate, boardShowChooseTemplatePicker} from "../redux/actions";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const BoardTemplate = ({showTemplatePicker, isFirstBoard,
                           boardChooseTemplate, boardShowChooseTemplatePicker
}) => {
    const [hasChosenTemplate, setHasChosenTemplate] = React.useState(false);

    const handleClose = () => {
        boardShowChooseTemplatePicker(false);
    };

    const handleTemplateOptionChosen = (templateName, templateColumns) => {
        boardChooseTemplate(templateName, templateColumns);
        handleClose();
    };


    let boardTemplatOptions = [
        {
            title: "Same as last retro", // warning: logic elsewhere uses this hardcoded name
            image: copyImage,
            columnNames: []
        },
        {
            title: "Classic",  // warning: logic elsewhere uses this hardcoded name
            image: originalImage,
            columnNames: ['What went well?', "What didn't go well?"]
        },
        {
            title: "Start, Stop, Continue",
            image: trafficLightImage,
            columnNames: ['Start', 'Stop', 'Continue']
        },
        {
            title: "Mad, Sad, Glad",
            image: madImage,
            columnNames: ['Mad', 'Sad', 'Glad']
        },
        {
            title: "4L's Retrospective",
            image: lImage,
            columnNames: ['Liked', 'Learned', 'Lacked', 'Longed For']
        },
        {
            title: "Starfish",
            image: starfishImage,
            columnNames: ['Keep Doing', 'Less of', 'More of', 'Start Doing', 'Stop Doing']
        },
        {
            title: "Speed Car",
            image: speedCarImage,
            columnNames: ['Engine - What make us move faster?', 'Parachute - What is slowing us down?']
        },
        {
            title: "KALM",
            image: plusImage,
            columnNames: ['Keep', 'Add', 'Less', 'More']
        },
        {
            title: "Three Little Pigs",
            image: pigImage,
            columnNames: ['House of Straw', 'House of Sticks', 'House of Bricks']
        },
        {
            title: "SWOT Analysis",
            image: strengthImage,
            columnNames: ['Strength', 'Weakness', 'Opportunity', 'Threat']
        },
        {
            title: "Sailboat",
            image: sailboatImage,
            columnNames: ['What is the wind pushing our sails that make us go fast?', 'What anchors are holding us back?', 'What rocks are ahead of us that risk our future?', 'What is our ideal island destination?']
        },
        {
            title: "DAKI",
            image: duckImage,
            columnNames: ['Drop', 'Add', 'Keep', 'Improve']
        },
    ];

    // Remove "Same as last retro" if they're about to create their first retro
    if (isFirstBoard) {
        boardTemplatOptions.shift();

        if (!hasChosenTemplate) {
            boardChooseTemplate(boardTemplatOptions[0].title, boardTemplatOptions[0].columnNames);
        }
    }

    return (
        <div>
            <Dialog
                fullWidth={true}
                open={showTemplatePicker}
                onClose={handleClose}
                maxWidth="xl"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Choose a board template:
                </DialogTitle>
                <DialogContent style={{display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                    {boardTemplatOptions.map((templateOption) => (
                        <div onClick={() => {handleTemplateOptionChosen(templateOption.title, templateOption.columnNames); setHasChosenTemplate(true);}} style={{minHeight: '200px'}} key={templateOption.title}>
                            <BoardTemplateOption
                                title={templateOption.title}
                                image={templateOption.image}
                                columnNames={templateOption.columnNames}
                            />
                        </div>
                    ))}
                </DialogContent>
            </Dialog>
        </div>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        showTemplatePicker: state.board.showTemplatePicker
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        boardChooseTemplate: (templateName, columnNames) => {
            dispatch(boardChooseTemplate(templateName, columnNames))
        },
        boardShowChooseTemplatePicker: (shouldShow) => {
            dispatch(boardShowChooseTemplatePicker(shouldShow));
        }
    }
};

const BoardTemplateContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardTemplate);
export default BoardTemplateContainer