import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import {isEmpty} from "react-redux-firebase";

const MarketingPage = () => {
    const history = useHistory();
    const auth = useSelector(state => state.firebase.auth)
    if(!isEmpty(auth)) {
        history.push('/board');
    } else {
        window.location.href = 'https://realtimeretro.com';
    }
    return (<div></div>);
};

export default MarketingPage;
