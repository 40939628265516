import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const DeleteModal = ({children, onClickDelete, recordName, className}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
      <>
          <span onClick={handleClickOpen} className={className}>
              {children}
          </span>
          <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this {recordName}?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose} color="primary">
                      Cancel
                  </Button>
                  <Button onClick={() => {handleClose(); onClickDelete();}} color="secondary" autoFocus>
                      Delete
                  </Button>
              </DialogActions>
          </Dialog>
      </>
    );
};

export default DeleteModal;