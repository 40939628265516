import { connect } from 'react-redux'
import {boardCreate, boardToggleCreateForm, clearDemo, userSetHasClearedDemo} from "../redux/actions";
import CreateBoard from '../components/CreateBoard'
import {getUserId} from "../utilities/UserUtility";
import {boardShowChooseTemplatePicker, userSetHasViewedDemo} from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
    return {
        boardShowCreateForm: state.board.boardShowCreateForm,
        chosenBoardTemplateName: state.board.chosenBoardTemplateName,
        chosenBoardTemplateColumns: state.board.chosenBoardTemplateColumns
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickToggleDisplayForm: (shouldShowForm) => {
            dispatch(boardToggleCreateForm(shouldShowForm))
        },
        onClickBoardCreate: (boardName, columnNames, heartLimit) => {
            // Board id might not be defined if all the boards were deleted.
            if (ownProps.boardId && ownProps.user.hasViewedDemo === true && ownProps.user.hasClearedDemo === false) {
                dispatch(clearDemo(ownProps.teamId, ownProps.boardId, ownProps.userId));
            }
            
            if (ownProps.boardId && ownProps.user.hasViewedDemo === false) {
                dispatch(userSetHasViewedDemo(ownProps.userId, true));
                dispatch(userSetHasClearedDemo(ownProps.userId, true));
            }

            dispatch(boardCreate(ownProps.teamId, boardName, columnNames, heartLimit));
        },
        boardShowChooseTemplatePicker: (shouldShow) => {
            dispatch(boardShowChooseTemplatePicker(shouldShow));
        }
    }
};

const CreateBoardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateBoard);
export default CreateBoardContainer