import React from 'react'
import '../App.css'
import BoardContainer from "../containers/BoardContainer";
import BoardColumnContainer from "../containers/BoardColumnContainer";
import CreateBoardColumnContainer from "../containers/CreateBoardColumnContainer";
import {compose} from "redux";
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import CommentSection from "../components/CommentSection";
import CommentContainer from "../containers/CommentContainer";
import CreateFeedbackContainer from "../containers/CreateFeedbackContainer";
import FeedbackContainer from '../containers/FeedbackContainer'
import {groupBy} from '../utilities/ArrayUtilities'
import CreateNewBoard from "../components/CreateNewBoard";
import Loading from "../components/Loading";
import {getAvatarColor} from '../utilities/ColorUtility'
import './BoardFirestoreContainer.css';
import {getUserId} from "../utilities/UserUtility";
import FadeIn from 'react-fade-in';
import ActionItemsColumn from "../components/ActionItemsColumn";
import CreateActionItemContainer from "../containers/CreateActionItemContainer";
import ActionItemsColumnContainer from "../containers/ActionItemColumnContainer";
import CreateBoardContainer from "../containers/CreateBoardContainer";
import AddIcon from "@material-ui/icons/Add";
import {IconButton, Tooltip} from "@material-ui/core";


const calculateCommentTotal = (feedbackId, comments)  => {
    return comments.filter((comment) => {
       return comment.feedbackId === feedbackId
    }).length;
};

const isFeedbackHearted = (feedbackHearts, userId, feedbackId) => {
    if (!feedbackHearts) return false;
    let feedbackHeartRecord = feedbackHearts[userId + '_' + feedbackId];
    return (feedbackHeartRecord && feedbackHeartRecord.isHearted) || false;
};

const isCommentHearted = (commentHearts, userId, messageId) => {
    if (!commentHearts) return false;
    let commentHeartRecord = commentHearts[userId + '_' + messageId];
    return (commentHeartRecord && commentHeartRecord.isHearted) || false;
};

const arrangeHeartsByRecordId = (orderedDatabaseHearts, recordIdColumnName) => {
    let feedbackHeartsByFeedbackId = [];
    if (orderedDatabaseHearts) {
        feedbackHeartsByFeedbackId = groupBy(orderedDatabaseHearts.filter((item) => item.isHearted), recordIdColumnName);
    }
    return feedbackHeartsByFeedbackId;
};

const getTotalHeartsForCurrentUser = (orderedDatabaseHearts, userId, unarchivedFeedback) => {
    if (!orderedDatabaseHearts) {
        return 0;
    }

    return orderedDatabaseHearts.filter((item) => {
        // filter out hearts that aren't liked to unarchived feedback
        let isHeartDeleted = unarchivedFeedback.filter(feedback => feedback.id === item.feedbackId).length === 0;

        return item.createdBy === userId && item.isHearted === true && !isHeartDeleted;
    }).length;
};

const getHeartsLeft = (heartLimit, totalFeedbackHeartsForCurrentUser) => {
  if (!heartLimit) return null;

  return heartLimit - totalFeedbackHeartsForCurrentUser;
};

const sortColumns = (columns) => {
    if (!columns || columns.length < 2) {
        return columns;
    }

    let sortedColumns = columns;
    const allColumnsHaveSortOrder = columns.reduce((previousValue, currentValue) => {
        return previousValue && typeof currentValue.sortOrder !== 'undefined';
    });

    // If all columns have the sortOrder property, sort by it
    if (allColumnsHaveSortOrder) {
        sortedColumns = columns.slice().sort((first, second) => {
            return first.sortOrder > second.sortOrder ? 1 : -1;
        });
    }

    return sortedColumns;
};

function BoardFirestoreContainer({teamId, user, actionItems, board, columns, boardIndex, feedback, comments, feedbackHearts, userId, boardCount, feedbackHeartsByFeedbackId, commentHearts, commentHeartsByCommentId, totalFeedbackHeartsForCurrentUser}) {
    if (!board) {
        return (
            <CreateNewBoard user={user} userId={userId} teamId={teamId}/>
        );
    }

    if (!isLoaded(user, actionItems, board, columns, feedback, comments, feedbackHearts, feedbackHeartsByFeedbackId, commentHearts, commentHeartsByCommentId, totalFeedbackHeartsForCurrentUser)) {
        return <Loading/>;
    }

    const sortedColumns = sortColumns(columns);
    const totalColumns = sortedColumns.length;

    const heartsLeft = getHeartsLeft(board.heartLimit, totalFeedbackHeartsForCurrentUser);
    const allowHearting = (heartsLeft === null) ? true : (heartsLeft > 0);

    // Don't show action items column if one already exists (for backwards compatibility before action items feature rolled out)
    const showActionItemsColumn = columns.filter(column => {return column.name === "Action Items"}).length === 0;

    return (
        <div>
            <BoardContainer user={user} userId={userId} name={board.name} teamId={teamId} boardId={board.id} boardCount={boardCount} boardIndex={boardIndex} heartsLeft={heartsLeft}>
                <div className="ColumnsContainer">
                    {sortedColumns.map((column, index) => (
                        <div key={index} className="ColumnContainer">
                            <BoardColumnContainer key={column.id} name={column.name} columnId={column.id} sortedColumns={sortedColumns} isLastColumn={index === (columns.length - 1)}>
                                <CreateFeedbackContainer teamId={teamId} columnId={column.id} boardId={board.id}/>
                                {feedback.map((feedbackItem) => (
                                    <div key={feedbackItem.id}>
                                        {column.id === feedbackItem.columnId &&
                                            <FadeIn>
                                                <FeedbackContainer
                                                key={feedbackItem.id}
                                                text={feedbackItem.text}
                                                commentTotal={calculateCommentTotal(feedbackItem.id, comments)}
                                                boardId={board.id}
                                                teamId={teamId}
                                                userName={feedbackItem.userName}
                                                avatarColor={getAvatarColor(feedbackItem.userName)}
                                                feedbackId={feedbackItem.id}
                                                isHearted={isFeedbackHearted(feedbackHearts, userId, feedbackItem.id)}
                                                allowHearting={allowHearting}
                                                totalHearts={feedbackHeartsByFeedbackId[feedbackItem.id] && (feedbackHeartsByFeedbackId[feedbackItem.id].length)}
                                                createdByLoggedInUser={userId === feedbackItem.createdBy}
                                            >
                                                <CommentSection boardId={board.id} feedbackId={feedbackItem.id} teamId={teamId}>
                                                    {comments.map((comment) => (
                                                        <div key={comment.id}>
                                                            {feedbackItem.id === comment.feedbackId &&
                                                                <FadeIn>
                                                                    <CommentContainer
                                                                        key={comment.id}
                                                                        teamId={teamId}
                                                                        boardId={board.id}
                                                                        commentId={comment.id}
                                                                        userName={comment.userName}
                                                                        avatarColor={getAvatarColor(comment.userName)}
                                                                        isHearted={isCommentHearted(commentHearts, userId, comment.id)}
                                                                        totalHearts={commentHeartsByCommentId[comment.id] && commentHeartsByCommentId[comment.id].length}
                                                                        createdByLoggedInUser={userId === comment.createdBy}
                                                                        text={comment.text}
                                                                    >{comment.text}</CommentContainer>
                                                                </FadeIn>
                                                            }
                                                        </div>
                                                    ))}
                                                </CommentSection>
                                            </FeedbackContainer>
                                            </FadeIn>
                                        }
                                    </div>
                                ))}
                            </BoardColumnContainer>
                        </div>
                    ))}
                    <CreateBoardColumnContainer boardId={board.id} teamId={teamId} totalColumns={totalColumns}/>
                    {showActionItemsColumn &&
                    <div className="ColumnContainer">
                        <ActionItemsColumnContainer name="Action Items" teamId={teamId} board={board} actionItems={actionItems} user={user} userId={userId} />
                    </div>
                    }
                </div>
            </BoardContainer>
        </div>
    )
}

export default compose(
    firestoreConnect((props, state) => {
        if (!props.board || !props.teamId) {
            return [];
        }

        const userId = getUserId(state.firebase);

        return [
            {
                collection: 'users',
                doc: userId,
            },
            {
                collection: 'actionItems',
                where: [
                    ['isComplete', '==', false],
                    ['isArchived', '==', false],
                    ['teamId', '==', props.teamId],
                ],
                orderBy: [
                    ['createdAt', 'desc']
                ]
            },
            {
                collection: 'columns',
                where: [
                    ['boardId', '==', props.board.id],
                    ['isArchived', '==', false],
                    ['teamId', '==', props.teamId],

                ],
                orderBy: [
                    ['createdAt']
                ]
            },
            {
                collection: 'feedback',
                where: [
                    ['boardId', '==', props.board.id],
                    ['isArchived', '==', false],
                    ['teamId', '==', props.teamId],
                ],
                orderBy: [
                    ['createdAt']
                ]
            },
            {
                collection: 'comments',
                where: [
                    ['boardId', '==', props.board.id],
                    ['isArchived', '==', false],
                    ['teamId', '==', props.teamId],
                ],
                orderBy: [
                    ['createdAt']
                ]
            },
            {
                collection: 'feedbackHearts',
                where: [
                    ['boardId', '==', props.board.id],
                    ['teamId', '==', props.teamId],
                ]
            },
            {
                collection: 'commentHearts',
                where: [
                    ['boardId', '==', props.board.id],
                    ['teamId', '==', props.teamId],
                ]
            },
        ];
    }),
    connect((state, props) => {
        return {
            userId: getUserId(state.firebase),
            user: state.firestore.data.user,
            actionItems: state.firestore.ordered.actionItems,
            columns: state.firestore.ordered.columns,
            feedback: state.firestore.ordered.feedback,
            comments: state.firestore.ordered.comments,
            feedbackHearts: state.firestore.data.feedbackHearts,
            feedbackHeartsByFeedbackId: arrangeHeartsByRecordId(state.firestore.ordered.feedbackHearts, 'feedbackId'),
            commentHearts: state.firestore.data.commentHearts,
            commentHeartsByCommentId: arrangeHeartsByRecordId(state.firestore.ordered.commentHearts, 'commentId'),
            totalFeedbackHeartsForCurrentUser: getTotalHeartsForCurrentUser(state.firestore.ordered.feedbackHearts, getUserId(state.firebase), state.firestore.ordered.feedback),
        }
    })
)(BoardFirestoreContainer)
