import { connect } from 'react-redux'
import {feedbackCreate} from "../redux/actions";
import CreateFeedback from '../components/CreateFeedback'

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.firestore.data.userTeamView
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickFeedbackCreate: (feedbackText, displayName) => {
            dispatch(feedbackCreate(ownProps.teamId, feedbackText, ownProps.columnId, ownProps.boardId, displayName))
        }
    }
};

const CreateFeedbackContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateFeedback);
export default CreateFeedbackContainer