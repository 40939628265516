import React from 'react'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore' // make sure you add this for firestore
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import configureStore from './redux/store'
import { firebase as firebaseConfig, reactReduxFirestoreConfig } from './firebase/config'
import './App.css'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Routes from './routes/Routes'
import './styles/App.scss'

// Initialize Firebase instance
firebase.initializeApp(firebaseConfig);

const initialState = window && window.__INITIAL_STATE__ // set initial state here
const store = configureStore(initialState, firebase);

export default function App() {
    const theme = createMuiTheme({
        palette: {
            primary: blue,
            secondary: {
                main: '#ff2a00',
            },
            warning: {
                main: '#ff9800',
            }
        },
    });

    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider
                firebase={firebase}
                config={reactReduxFirestoreConfig}
                dispatch={store.dispatch}
                createFirestoreInstance={createFirestoreInstance}>
                <ThemeProvider theme={theme}>
                    <Routes/>
                </ThemeProvider>
            </ReactReduxFirebaseProvider>
        </Provider>
    )
}
