import React, {useEffect} from 'react';
import { connect } from 'react-redux'
import {startDemo, clearDemo} from "../redux/actions";
import {Button} from '@material-ui/core';
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import LocalStorageUtility from "../utilities/LocalStorageUtility";
import {getUserId} from "../utilities/UserUtility";
import {compose} from "redux";

const Demo = ({user, userId,
              startDemo, clearDemo}) => {
    const [showClearButton, setShowClearButton] = React.useState(user.hasClearedDemo === false && user.hasViewedDemo === true);

    useEffect(() => {
        if (user.hasClearedDemo === true) {
            setShowClearButton(false);
        }
    });

    if (!isLoaded(user, userId)) {
        return null;
    }

    const startDemoButtonClicked = () => {
        startDemo(userId);

        // Only show clear button once enough time has passed for
        // action items to be created. This is to prevent someone
        // from clearing the board before action items are created.
        // If that were to happen, the action items would be created after it
        // was cleared.
        setTimeout(() => {
            setShowClearButton(true);
        }, 8000);
    };

    const clearDemoClicked = () => {
        clearDemo(userId);
        setShowClearButton(false);
    };

    return (
        <>
            {user.hasViewedDemo === false &&
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={startDemoButtonClicked}
                >
                    Show Demo
                </Button>
            }
            {showClearButton &&
                <Button
                    color="secondary"
                    variant="contained"
                    style={{backgroundColor: "#ff9800"}}
                    onClick={() => clearDemoClicked()}
                >
                    Clear Demo
                </Button>
            }
        </>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        userId: getUserId(state.firebase),
        user: state.firestore.data.user
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        startDemo: (userId) => {
            dispatch(startDemo(userId, ownProps.teamId));
        },
        clearDemo: (userId) => {
            dispatch(clearDemo(ownProps.teamId, ownProps.boardId, userId));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        let userId = getUserId(state.firebase);

        return [
            {
                collection: 'users',
                doc: userId,
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Demo)