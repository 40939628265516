import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles(theme => ({
    badge: {
        right: -3,
        top: 0,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0px',
        fontSize: '12px'
    },
}))(Badge);

export default StyledBadge;