import React from 'react'
import Typography from '@material-ui/core/Typography'
import "./Comment.css"
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from "@material-ui/core/Avatar/Avatar";
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles';
import "./Comment.css"
import StyledBadge from './StyledBadge'
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import onBlur from '../utilities/Blur'
import {getUserInitials} from "../utilities/UserUtility";

const useStyles = makeStyles(theme => ({
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '14px !important'
    },
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const Comment = ({children, userName, avatarColor, isHearted, isEditing, text, totalHearts, createdByLoggedInUser,
                     onClickCommentHeartToggle, onClickToggleIsEditing, onClickCommentUpdate, onClickDelete
}) => {
    const classes = useStyles();
    const [commentText, setCommentText] = React.useState(text);
    const userInitials = getUserInitials(userName);

    const submitPressed = () => {
        onClickCommentUpdate(commentText);
        onClickToggleIsEditing(false);
    };

    const handleKeypress = (e) => {
        if (e.ctrlKey) {
            setCommentText(commentText + '\n');
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            submitPressed();
        }
    };

    return (
      <Card className="Comment">
          <CardContent
              onBlur={(e) => onBlur(e, () => {onClickToggleIsEditing(false)})}
              onClick={() => !isEditing && createdByLoggedInUser && onClickToggleIsEditing(true)}
          >
              {!isEditing &&
                  <Typography color="textSecondary" className="CardText" gutterBottom>
                      {children}
                  </Typography>
              }

              {isEditing &&
              <form className="CreateComment">
                  <TextField
                      autoFocus
                      className="standard-multiline-flexible"
                      size="small"
                      multiline
                      rowsMax="10"
                      variant="outlined"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                      onKeyPress={(ev) => {handleKeypress(ev)}}
                  />
                  <Button position="right" className="FeedbackButton" variant="contained" color="primary"
                          onClick={submitPressed}
                  >
                      Save
                  </Button>
              </form>
              }
          </CardContent>
          {!isEditing &&
              <CardActions disableSpacing>
                  <Avatar aria-label="recipe" className="CardAvatar"  style={{backgroundColor: avatarColor}}>
                      {userInitials}
                  </Avatar>
                  <div className="CommentActionButtons">
                      {createdByLoggedInUser &&
                      <IconButton aria-label="edit" onClick={() => onClickDelete()}>
                          <Icon className="CardIcon">delete</Icon>
                      </IconButton>
                      }
                      <IconButton aria-label="favorite"
                                  onClick={() => onClickCommentHeartToggle()}>
                          <StyledBadge badgeContent={totalHearts} color="secondary">
                              <Icon className="CardIcon" color={(isHearted) ? 'secondary' : 'inherit'}>favorite</Icon>
                          </StyledBadge>
                      </IconButton>
                  </div>
              </CardActions>
          }
      </Card>
  )
};

export default Comment;