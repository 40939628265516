import React from 'react'
import '../App.css'
import "./TeamPickerFirestoreContainer.css";
import {compose} from "redux";
import {firestoreConnect, isLoaded, isEmpty} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {setTeamCurrentlyViewingTeam} from "../redux/actions";
import {getUserId} from "../utilities/UserUtility";
import TeamName from "./TeamName";
import {groupBy, objectToArray} from "../utilities/ArrayUtilities";

function getTeams(teamUsers) {
    if (!teamUsers) {
        return [];
    }

    let teamIds = [];
    Object.keys(teamUsers).forEach((teamUserId) => {
        const teamData = teamUsers[teamUserId];
        // Make sure team hasn't been deleted
        if (teamData === null || typeof teamData.teamId === 'undefined') {
            return;
        }
        teamIds.push(teamData.teamId);
    });
    return teamIds;
}

function TeamPickerFirestoreContainer({user, id, teamUsers,
                                          onClickSetTeamView
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    if (!isLoaded(user, teamUsers) && !isEmpty(teamUsers)) {
        return (<div></div>);
    }

    // Sometimes the id isn't set when a user first signs up.
    if (!id) {
        return (<div></div>);
    }

    const teamUsersFromFilter =  objectToArray(teamUsers).filter((teamUser) => teamUser && teamUser.teamId === id);
    const teamUser = teamUsersFromFilter.length > 0 && teamUsersFromFilter[0];

    if (!teamUser) {
        return (<div></div>);
    }

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (teamId) => {
        if (typeof teamId === 'string') {
            onClickSetTeamView(teamId);
        }
        setAnchorEl(null);
    };

    const teamIds = getTeams(teamUsers);
    const shouldShowTeamPicker = teamUsers !== null && Object.keys(teamUsers).length > 1;

    return (
        <div>
            {shouldShowTeamPicker &&
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" color="inherit" onClick={handleClick}>
                        <TeamName teamId={teamUser.teamId}/> &nbsp;▼
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {teamIds.map((teamId) => (
                            <span key={teamId}>
                                <MenuItem onClick={() => handleClose(teamId)}>
                                    <TeamName teamId={teamId}/>
                                </MenuItem>
                            </span>
                        ))}
                    </Menu>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        teamUsers: state.firestore.data.teamUsers,
        user: state.firestore.data.user,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickSetTeamView: (teamId) => {
            return dispatch(setTeamCurrentlyViewingTeam(teamId));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const userId = getUserId(state.firebase);
        return [
            {
                collection: 'users',
                doc: userId,
                storeAs: 'user'
            },
            {
                collection: 'teamUsers',
                where: [
                    ['userId', '==', userId]
                ]
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(TeamPickerFirestoreContainer)
