import React from 'react'
import '../App.css'
import {compose} from "redux";
import {firestoreConnect, isLoaded, isEmpty} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import {Tab} from '@material-ui/core';

function TeamNameTab({props, team}) {
    // Remove dispatch key from props object to avoid an error
    // because of the spreading operator on props below
    delete props.dispatch;

    if (!isLoaded(team) && isEmpty(team)) {
        // We need to return a tab component here instead of null, otherwise the first
        // tab won't get auto-highlighted
        return (
            <Tab
                component="a"
                label=""
                onClick={(e) => {e.preventDefault();}}
                {...props}
            />
        );
    }
    return (
        <Tab
            component="a"
            label={team && team.name}
            onClick={(e) => {e.preventDefault();}}
            {...props}
        />
    );
}

const mapStateToProps = (state, ownProps) => {
    const teams = state.firestore.data.teams;
    return {
        props: ownProps,
        team: teams && teams[ownProps.teamid],
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {}
};

export default compose(
    firestoreConnect((props, state) => {
        if (!props.teamid) {
            return [];
        }
        return [
            {
                collection: 'teams',
                doc: props.teamid,
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(TeamNameTab)
