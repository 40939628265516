import React from 'react';
import NavBarContainer from "../containers/NavBarContainer";
import CurrentTeamFirestoreContainer from '../firestoreContainers/CurrentTeamFirestoreContainer'
import Help from "../components/Help"

const HelpPage = () => {
    return (
        <div>
            <CurrentTeamFirestoreContainer>
                <NavBarContainer/>
                <Help/>
            </CurrentTeamFirestoreContainer>
        </div>
    )
};

export default HelpPage;
