import React from 'react';
import NavBarContainer from "../containers/NavBarContainer";
import CurrentTeamFirestoreContainer from '../firestoreContainers/CurrentTeamFirestoreContainer';
import TeamSelectorFirestoreContainer from '../firestoreContainers/TeamSelectorFirestoreContainer';

const TeamPage = () => {
    return (
        <div>
            <CurrentTeamFirestoreContainer>
                <NavBarContainer/>
                <TeamSelectorFirestoreContainer/>
            </CurrentTeamFirestoreContainer>
        </div>
    )
};

export default TeamPage;
