/****************
Board
*****************/
import {getCurrentTimestamp} from "../utilities/FirestoreUtility";
import {getUserId} from "../utilities/UserUtility";
import LocalStorageUtility from '../utilities/LocalStorageUtility';
import {isMobileOnly, browserName, fullBrowserVersion, mobileVendor, mobileModel, osName, osVersion, isTablet} from 'react-device-detect';
import moment from 'moment';

export const BOARD_TOGGLE_SHOW_CREATE_FORM = 'BOARD_TOGGLE_SHOW_CREATE_FORM';
export function boardToggleCreateForm(shouldShowForm) {
    return {
        type: BOARD_TOGGLE_SHOW_CREATE_FORM,
        shouldShowForm
    }
}

function getDefaultBoardName()
{
    return 'Retro - ' + moment().format('MMMM Do, YYYY');
}

export function boardCreate(teamId, name, columnNames, heartLimit) {
        return (dispatch, getState, args) => {
            let firestore = args.firebase.firestore;
            if (name === "") {
                name = getDefaultBoardName();
            }

            return dispatch(createRecord('boards', {name, heartLimit}, teamId)).then((data) => {
                const boardId = data.id;
                const promises = [];

                if (columnNames.length === 0) {
                    promises.push(dispatch(copyOverColumnsFromLastBoard(teamId, name, boardId)));
                } else {
                    promises.push(dispatch(createColumns(teamId, boardId, columnNames)));
                }

                promises.push(dispatch(boardToggleCreateForm(false)));
                promises.push(dispatch(boardSetViewId()));

                let boardCreationDate = getCurrentTimestamp(firestore);
                promises.push(dispatch(slackUpdateBoardInfo(teamId, boardCreationDate, boardId)));

                return Promise.all(promises).then(() => {
                    return boardId
                });
            });
    }
}

export function getSortedColumns(teamId, boardId) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        return new Promise(resolve => {
            return firestore().collection('columns')
                .where('boardId', '==', boardId)
                .where('teamId', '==', teamId)
                .where('isArchived', '==', false)
                .orderBy('createdAt')
                .get().then(columns => {

                    let columnsArray = columns.docs.map(doc => {
                      return {name: doc.get('name'), sortOrder: doc.get('sortOrder')};
                    });

                    if (columnsArray.length === 0) {
                        resolve(columnsArray);
                        return;
                    }

                    const allColumnsHaveSortOrder = columnsArray.reduce((previousValue, currentValue) => {
                        return previousValue && typeof currentValue.sortOrder !== 'undefined';
                    });

                    // If all columns have the sortOrder property, sort by it
                    if (allColumnsHaveSortOrder) {
                        columnsArray = columnsArray.sort((first, second) => {
                           return first.sortOrder > second.sortOrder ? 1 : -1;
                        });
                    }

                    // Otherwise return the default created_at sort order
                    resolve(columnsArray);
                });
        });
    }
}

export function createColumns(teamId, boardId, columnNames) {
    return (dispatch, getState, args) => {
        for (let i = 0; i < columnNames.length; i++) {
            if (columnNames) {
                dispatch(boardColumnCreate(teamId, columnNames[i], boardId, i));
            }
        }
    }
}

export function copyOverColumnsFromLastBoard(teamId, name, boardId) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;

        const createColumns = (columns, boardId) => {
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].name !== "Action Items") {
                    dispatch(boardColumnCreate(teamId, columns[i].name, boardId, i));
                }
            }
        };

        firestore().collection('boards')
            .where('teamId', '==', teamId)
            .where('isArchived', '==', false)
            .orderBy('createdAt', 'desc')
            .get()
            .then(
                (boards) => {
                    if (boards.docs.length > 1) {
                        let previousBoardId = boards.docs[1].id;

                        // Copy over columns in a sorted way so that even if the last
                        // board's columns weren't sorted, the new board's columns will have a sort order
                        dispatch(getSortedColumns(teamId, previousBoardId)).then((columns) => {
                            createColumns(columns, boardId)
                        });
                    }
                }
            )
    }
}

export const BOARD_IS_EDITING = 'BOARD_IS_EDITING';
export function boardToggleIsEditing(isEditing) {
    return {
        type: BOARD_IS_EDITING,
        isEditing
    }
}

export function boardUpdate(boardId, name) {
    if (name.trim().length === 0) {
        name = getDefaultBoardName();
    }
    return singleFieldUpdate('boards', boardId, 'name', name, boardToggleIsEditing(false));
}

export function boardDelete(boardId) {
    return deleteRecord('boards', boardId);
}

export const BOARD_SET_VIEW_ID = 'BOARD_SET_VIEW_ID';
export function boardSetViewId(boardId){
    return {
        type: BOARD_SET_VIEW_ID,
        boardId: boardId
    }
}

export const BOARD_CHOOSE_TEMPLATE = 'BOARD_CHOOSE_TEMPLATE';
export function boardChooseTemplate(templateName, columnNames) {
    return {
        type: BOARD_CHOOSE_TEMPLATE,
        templateName: templateName,
        columnNames: columnNames
    }
}

export const BOARD_SHOW_CHOOSE_TEMPLATE_PICKER = 'BOARD_SHOW_CHOOSE_TEMPLATE_PICKER';
export function boardShowChooseTemplatePicker(shouldShow) {
    return {
        type: BOARD_SHOW_CHOOSE_TEMPLATE_PICKER,
        shouldShow: shouldShow
    }
}

/*****************
 Column
 *****************/
export const BOARD_COLUMN_TOGGLE_SHOW_CREATE_FORM = 'BOARD_COLUMN_TOGGLE_SHOW_CREATE_FORM';
export function boardColumnToggleShowCreateForm(shouldShowForm) {
    return {
        type: BOARD_COLUMN_TOGGLE_SHOW_CREATE_FORM,
        shouldShowForm
    }
}

export function boardColumnCreate(teamId, name, boardId, sortOrder) {
    return createRecordWithDispatch('columns', {name, boardId, sortOrder}, teamId);
}

export const COLUMN_IS_EDITING = 'COLUMN_IS_EDITING';
export function columnToggleIsEditing(isEditing, columnId) {
    return {
        type: COLUMN_IS_EDITING,
        isEditing,
        columnId
    }
}

export function boardColumnUpdate(columnId, name) {
    name = name.trim() === "" ? "New Column" : name;
    return singleFieldUpdate('columns', columnId, 'name', name, columnToggleIsEditing(false, columnId));
}

export function sortColumnsAfterDeletion(columnIdToDelete, sortedColumns) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;

        // Return immediately if there's nothing to sort
        if (!sortedColumns || sortedColumns.length < 2) {
            return;
        }

        // Don't sort columns if deleting the last one, since they're still sorted properly
        const isDeletingLastColumn = sortedColumns[sortedColumns.length - 1].id === columnIdToDelete;
        if (isDeletingLastColumn) {
            return;
        }

        // Remote deleted column from sorted columns
        const columnsAfterDelete = sortedColumns.filter(column => {
            return column.id !== columnIdToDelete;
        });

        // Update sort order of remaining columns
        let updateQueries =[];
        columnsAfterDelete.forEach((sortedColumn, index) => {
            updateQueries.push(firestore().collection('columns').doc(sortedColumn.id).update({sortOrder: index}));
        });

        return Promise.all(updateQueries);
    }
}

export function boardColumnDelete(id, sortedColumns) {
    return (dispatch, getState, args) => {
        dispatch(deleteRecord('columns', id));
        return dispatch(sortColumnsAfterDeletion(id, sortedColumns));
    }
}

/*****************
 Feedback
 *****************/
export function feedbackCreate(teamId, text, columnId, boardId, userDisplayName) {
    return (dispatch, getState, args) => {
        return dispatch(createRecord('feedback', {text, columnId, boardId, userName: userDisplayName}, teamId)).then((snapshot) => {
            return snapshot;
        });
    }
}

export function feedbackUpdate(feedbackId, text) {
    return singleFieldUpdate('feedback', feedbackId, 'text', text);
}

export const FEEDBACK_IS_EDITING = 'FEEDBACK_IS_EDITING';
export function feedbackToggleIsEditing(isEditing, feedbackId) {
    return {
        type: FEEDBACK_IS_EDITING,
        isEditing,
        feedbackId
    }
}

export function feedbackDelete(id) {
    return deleteRecord('feedback', id);
}

/*****************
 Action Item
 *****************/
export function actionItemCreate(teamId, text, boardId, userDisplayName) {
    return (dispatch, getState, args) => {
        return dispatch(createRecord('actionItems', {
            text,
            isComplete: false,
            boardId,
            userName: userDisplayName
        }, teamId)).then((snapshot) => {
            return snapshot;
        });
    }
}

export function actionItemUpdate(actionItemId, text) {
    return singleFieldUpdate('actionItems', actionItemId, 'text', text);
}

export const ACTION_ITEM_IS_EDITING = 'ACTION_ITEM_IS_EDITING';
export function actionItemToggleIsEditing(isEditing, actionItemId) {
    return {
        type: ACTION_ITEM_IS_EDITING,
        isEditing,
        actionItemId
    }
}

export function actionItemDelete(id) {
    return deleteRecord('actionItems', id);
}

export function deleteAllActionItemsForBoard(teamId, boardId) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        let batch = firestore().batch();
        return firestore().collection('actionItems')
            .where('boardId', '==', boardId)
            .where('teamId', '==', teamId)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                });

                return batch.commit();
            });
    }
}

export function actionItemSetIsComplete(actionItemId, value) {
    return singleFieldUpdate('actionItems', actionItemId, 'isComplete', value);
}

/*****************
 Comment
 *****************/
export const COMMENT_IS_EDITING = 'COMMENT_IS_EDITING';
export function commentToggleIsEditing(isEditing, commentId) {
    return {
        type: COMMENT_IS_EDITING,
        isEditing,
        commentId
    }
}

export function commentCreate(teamId, text, feedbackId, boardId, userDisplayName) {
    return (dispatch, getState, args) => {
        return dispatch(createRecord('comments', {text, feedbackId, boardId, userName: userDisplayName}, teamId));
    }
}

export function commentUpdate(commentId, text) {
    return singleFieldUpdate('comments', commentId, 'text', text);
}

export function commentDelete(id) {
    return deleteRecord('comments', id);
}

/*****************
 Feedback Heart
 *****************/
export function feedbackHeartToggle(teamId, boardId, feedbackId, isHearted, createdByOverride) {
    return (dispatch, getState, args) => {
        const createdBy = typeof createdByOverride == "undefined" ? getUserId(args.firebase) :createdByOverride;
        let firestore = args.firebase.firestore;
        const createdAt = getCurrentTimestamp(firestore);
        return firestore().collection('feedbackHearts').doc(createdBy + '_' + feedbackId).set(
            {
                teamId,
                createdBy,
                boardId,
                feedbackId,
                createdAt,
                isHearted
            }
        );
    }
}

/*****************
 Comment Heart
 *****************/
export function commentHeartToggle(teamId, boardId, commentId, isHearted, createdByOverride) {
    return (dispatch, getState, args) => {
        const createdBy = typeof createdByOverride === "undefined" ? getUserId(args.firebase) : createdByOverride;
        let firestore = args.firebase.firestore;
        const createdAt = getCurrentTimestamp(firestore);
        return firestore().collection('commentHearts').doc(createdBy + '_' + commentId).set(
            {
                teamId,
                createdBy,
                boardId,
                commentId,
                createdAt,
                isHearted
            }
        );
    }
}

/*****************
Team
 *****************/
export function updateTeam(teamId, name) {
    return singleFieldUpdate('teams', teamId, 'name', name);
}

export function teamCreate(name, email, displayName) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        return dispatch(createRecord('teams', {name}, null)).then((data) => {
            const teamId = data.id;
            firestore().collection('teams').doc(teamId).get().then(data => {
                return dispatch(createNewTeamUser(teamId, email, displayName)).then(() => {
                    dispatch(shareUrlCreate(teamId));
                    return dispatch(createInitialBoard(teamId));
                });
            });
        });
    }
}

export const TEAM_SET_TAB_VIEW_INDEX = 'TEAM_SET_TAB_VIEW_INDEX';
export function setTeamTabViewIndex(index) {
    return {
        type: TEAM_SET_TAB_VIEW_INDEX,
        index: index
    }
}

export const TEAM_SET_CURRENTLY_VIEWING_TEAM = 'TEAM_SET_CURRENTLY_VIEWING_TEAM';
export function setTeamCurrentlyViewingTeam(teamId) {
    return {
        type: TEAM_SET_CURRENTLY_VIEWING_TEAM,
        teamId
    }
}

export const TEAM_IS_CREATING = 'TEAM_IS_CREATING';
export function setTeamIsCreating(isCreating) {
    return {
        type: TEAM_IS_CREATING,
        isCreating
    }
}

export function teamDelete(id, teamUsers) {
    return (dispatch, getState, args) => {

        // Reset tab view index
        dispatch(setTeamTabViewIndex(0));

        // First delete team, then delete team users to not get insufficient privilege errors from not owning team.
        const createdBy = getUserId(args.firebase);
        dispatch(hardDeleteRecord('teams', id)).then(() => {
            let firestore = args.firebase.firestore;

            // Delete team users that aren't the currently logged in team users
            const teamUsersOtherThanSelf =  teamUsers.filter((teamUser => teamUser.userId !== createdBy));
            const deleteOtherTeamUsersPromises = [];
            if (teamUsersOtherThanSelf) {
                teamUsersOtherThanSelf.forEach((teamUser) => {
                    deleteOtherTeamUsersPromises.push(firestore().collection('teamUsers').doc(teamUser.id).delete());
                });
            }

            // Make sure *all* other team users are deleted
            Promise.all(deleteOtherTeamUsersPromises).then(() => {
                // Finally, delete the currently logged in team user
                const selfTeamUser = teamUsers.filter(teamUser => teamUser.userId === createdBy);
                firestore().collection('teamUsers').doc(selfTeamUser[0].id).delete();
            });
        });
    };
}

export function createInitialBoard (teamId) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        const createdAt = getCurrentTimestamp(firestore);
        const createdBy = getUserId(args.firebase);
        return firestore().collection('boards').add({
            teamId,
            createdBy,
            name: "My First Retro",
            isArchived: false,
            createdAt
        }).then((boardData) => {
            const boardId = boardData.id;
            return dispatch(createInitialBoardColumns(boardId, teamId, createdBy)).then(() => {
                return boardId;
            });
        }).catch((reason => {
            console.log(reason);
        }));
    }
}

function createInitialBoardColumns(boardId, teamId, createdBy) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        let columnsToCreate = [
            'What went well?',
            "What didn't go well?"
        ];

        let columnPromises = [];
        for (let i = 0; i < columnsToCreate.length; i++) {
            let createdAt = getCurrentTimestamp(firestore);
            columnPromises.push(firestore().collection('columns').add({
                sortOrder: i,
                boardId,
                teamId,
                createdBy,
                createdAt,
                isArchived: false,
                name: columnsToCreate[i]
            }));
        }

        return Promise.all(columnPromises)
    }
}

/*****************
 Team User
 *****************/
export function teamUserDelete(teamUserId) {
    return hardDeleteRecord('teamUsers', teamUserId);
}

export function createNewTeamUser(teamId, email, displayName) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        const createdAt = getCurrentTimestamp(firestore);
        const userId = getUserId(args.firebase);

        const documentKey = teamId + '_' + userId;
        const dataToAdd = {
            userId,
            teamId,
            createdAt,
            email,
            displayName
        };
        return firestore().collection('teamUsers').doc(documentKey).set(dataToAdd);
    }
}

export function createSharedTeamUser(teamId, token, email, displayName) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        const createdAt = getCurrentTimestamp(firestore);
        const userId = getUserId(args.firebase);

        const documentKey = teamId + '_' + userId;
        const dataToAdd = {
            userId,
            teamId,
            createdAt,
            token,
            email,
            displayName
        };

        return firestore().collection('teamUsers').doc(documentKey).set(dataToAdd);
    }
}

/*****************
 Share URL
 *****************/
export function shareUrlCreate(teamId) {
    return createRecord('shareUrls', {}, teamId);
}

/*****************
 User
 *****************/
export function userUpdate(userId, displayName, email) {
    const updateObject = {
        displayName,
        email
    };

    return recordUpdate('users', userId, updateObject);
}

export function userSetHasViewedDemo(userId, hasViewedDemo) {
    return (dispatch, getState, args) => {
        return dispatch(recordUpdate('users', userId, {hasViewedDemo}));
    }
}

export function userSetHasClearedDemo(userId, hasClearedDemo) {
    return (dispatch, getState, args) => {
        return dispatch(recordUpdate('users', userId, {hasClearedDemo}));
    }
}

export function userSetActionItemsFilter(userId, actionItemsFilter) {
    return (dispatch, getState, args) => {
        return dispatch(recordUpdate('users', userId, {actionItemsFilter}));
    }
}

export function setInitialUserData(userId) {
    return (dispatch, getState, args) => {
        dispatch(recordUpdate('users', userId, {
            isMobile: isMobileOnly,
            isTablet,
            browserName,
            fullBrowserVersion,
            mobileVendor,
            mobileModel,
            osName,
            osVersion,
            isSyncedToDrip: false,
        }));
    }
}

/*****************
 Admin Impersonation User
 *****************/
export const SET_ADMIN_IMPERSONATION_USER = 'SET_ADMIN_IMPERSONATION_USER';
export function setAdminImpersonationUser(userId) {
    LocalStorageUtility().setAdminImpersonationUserId(userId);

    // Need to return something here even though it's not used
    return {
        type: SET_ADMIN_IMPERSONATION_USER,
        userId
    }
}

/*****************
 Authentication
 *****************/
export function logout() {
    return (dispatch, getState, args) => {
        return args.firebase.logout();
    }
}

/*****************
 Integrations - Slack
 *****************/
export function storeSlackCode(teamId, code) {
    return singleFieldUpdate('teams', teamId, 'slackCode', code);
}

export function slackUpdateDaysAfterRetroToSendMessage(teamId, days) {
    return singleFieldUpdate('integrationSlack', teamId, 'daysAfterRetroToSendMessage', days);
}

export function slackUpdateIsActive(teamId, isActive) {
    return singleFieldUpdate('integrationSlack', teamId, 'isActive', isActive);
}

export function slackUpdateBoardInfo(teamId, boardCreationDate, boardId) {
    return (dispatch, getState, args) => {
        let promises = [];
        promises.push(dispatch(singleFieldUpdate('integrationSlack', teamId, 'lastBoardCreationDate', boardCreationDate)));
        promises.push(dispatch(singleFieldUpdate('integrationSlack', teamId, 'lastBoardId', boardId)));
        // purposely catch errors in case integrationSlack isn't set up
        return Promise.all(promises).then().catch(error => {console.log(error)});
    }
}

export function slackUpdateSendTestMessage(teamId, shouldSendTestMessage) {
    return singleFieldUpdate('integrationSlack', teamId, 'sendTestMessage', shouldSendTestMessage);
}

/*****************
 Demo
 *****************/
export function clearDemo(teamId, boardId, userId) {
    return (dispatch, getState, args) => {
        return Promise.all([
            dispatch(userSetHasClearedDemo(userId, true)),
            dispatch(boardDelete(boardId)),
            dispatch(deleteAllActionItemsForBoard(teamId, boardId))
        ]);
    }
}

export function startDemo(userId, teamId) {
    const getRandomDisplayName = (nameToExclude) => {
        let displayNames = [
            "Benny Rogers",
            "Sarah Parker",
            "Jamal Tairim",
            "Gina Davis",
            "Hanul Kumbaya",
            "Roger Edwards",
            "Allison Parker",
            "Eddy Carver",
            "Miguel Cabrera",
        ];

        if (nameToExclude) {
            displayNames.splice(displayNames.indexOf(nameToExclude), 1);
        }

        return displayNames[Math.floor(Math.random() * (displayNames.length))];
    };

    return (dispatch, getState, args) => {
        dispatch(userSetHasViewedDemo(userId, true));
        const getFeedbackObject = (text, column, heartsToCreate, comments) => {
            return {
                feedback: {text, column, displayName: getRandomDisplayName(), heartsToCreate},
                comments
            }
        };

        const getCommentObject = (text, heartsToCreate) => {
            return {text, heartsToCreate};
        };

        let firestore = args.firebase.firestore();

        let feedbackToAdd = [
            getFeedbackObject("Great teamwork this sprint!", 0, 3, [
                getCommentObject("Agreed. Thanks Sarah for helping me out yesterday. You were a huge help.", 1),
            ]),
            getFeedbackObject("Trying out pair programming went well", 0, 0, [
                getCommentObject("I learned some great tricks, so it was really beneficial.", 2)
            ]),
            getFeedbackObject("We don't have very good test coverage", 1, 3, [
                getCommentObject("We need more unit tests on our latest module.", 1),
                getCommentObject("We also need integration tests.", 2),
                getCommentObject("I think this is probably why we've been so reactive lately.", 1),
            ]),
            getFeedbackObject("Progress made on important pain points", 0, 2),
            getFeedbackObject("Lots of fire fighting. We need to be more proactive.", 1, 4),
            getFeedbackObject("Really good push at the end. We completed everything we committed to.", 0, 3),
            getFeedbackObject("Deployment issues", 1, 0),
            getFeedbackObject("Great demo", 0, 5),
            getFeedbackObject("Dev environment issues.", 1, 0),
        ].reverse();

        let actionItemsToAdd = [
            {
                text: "Improve test coverage. Better unit/functional tests. Hopefully this will help us be less reactive.",
                displayName: getRandomDisplayName()
            },
            {
                text: "John to work with the ops team to try to fix deployment/dev environment issues.",
                displayName: getRandomDisplayName()
            },
            {
                text: "Work should be split into smaller chunks so most of it can be done in less than 3 days.",
                displayName: getRandomDisplayName()
            },
        ];

        // Don't change this, because firestore rules look for it
        const demoUserPrependedName = 'demo_';

        dispatch(createInitialBoard(teamId)).then((boardId) => {
            firestore.collection('columns')
                .where('boardId', '==', boardId)
                .where('isArchived', '==', false)
                .where('teamId', '==', teamId)
                .orderBy('sortOrder')
                .get().then(querySnapshot => {
                let columnIds = [];
                querySnapshot.forEach((doc) => {
                    columnIds.push(doc.id);
                });

                const createActionItem = (timeoutTime) => {
                    setTimeout(() => {
                        if (actionItemsToAdd.length === 0) return;
                        const actionItem = actionItemsToAdd.pop();
                        dispatch(actionItemCreate(teamId, actionItem.text, boardId, actionItem.displayName)).then((data) => {
                            createActionItem(1000);
                        });
                    }, timeoutTime);
                };

                const createCommentHeart = (timeoutTime, teamId, boardId, commentId, heartsToCreate) => {
                    setTimeout(() => {
                        const remainingHeartsToCreate = heartsToCreate - 1;
                        if (heartsToCreate > 0) {
                            dispatch(commentHeartToggle(teamId, boardId, commentId, true, demoUserPrependedName + remainingHeartsToCreate.toString()));
                            createCommentHeart(Math.round(Math.random() * 4000) + 2000, teamId, boardId, commentId, remainingHeartsToCreate);
                        }
                    }, timeoutTime);
                };

                const createComment = (feedbackId, feedbackItem, timeoutTime) => {
                    setTimeout(() => {
                        const commentItem = feedbackItem.comments && feedbackItem.comments.reverse().pop();
                        if (commentItem) {
                            dispatch(commentCreate(teamId, commentItem.text, feedbackId, boardId, getRandomDisplayName(feedbackItem.feedback.displayName))).then((data) => {
                                createCommentHeart(Math.round(Math.random() * 2000) + 2000, teamId, boardId, data.id, commentItem.heartsToCreate);
                            });
                            createComment(feedbackId, feedbackItem, Math.round(Math.random() * 2000));
                        }
                    }, timeoutTime);
                };

                const createFeedbackHeart = (timeoutTime, teamId, boardId, feedbackId, heartsToCreate) => {
                    setTimeout(() => {
                        const remainingHeartsToCreate = heartsToCreate - 1;
                        if (heartsToCreate > 0) {
                            dispatch(feedbackHeartToggle(teamId, boardId, feedbackId, true, demoUserPrependedName + remainingHeartsToCreate.toString()));
                            createFeedbackHeart(Math.round(Math.random() * 2000) + 2000, teamId, boardId, feedbackId, remainingHeartsToCreate);
                        }
                    }, timeoutTime);
                };

                const createFeedback = (timeoutTime) => {
                    setTimeout(() => {
                        if (feedbackToAdd.length === 0) {
                            return;
                        }

                        if (feedbackToAdd.length === 7) {
                            createActionItem(0);
                        }
                        const feedbackItem = feedbackToAdd.pop();
                        dispatch(feedbackCreate(teamId, feedbackItem.feedback.text, columnIds[feedbackItem.feedback.column], boardId, feedbackItem.feedback.displayName)).then((data) => {
                            createComment(data.id, feedbackItem, Math.round(Math.random() * 2000));
                            createFeedbackHeart(Math.round(Math.random() * 2000) + 2000, teamId, boardId, data.id, feedbackItem.feedback.heartsToCreate);
                        });
                        createFeedback(Math.round(Math.random() * 2000));
                    }, timeoutTime);
                };
                createFeedback(0);
            });
        });
    }

}

/*****************
 Generic Firestore CRUD
 *****************/
export function singleFieldUpdate(collectionName, documentId, fieldName, fieldValue, dispatchFunction) {
    const updateObject = {
        [fieldName]: fieldValue
    };

    return recordUpdateWithDispatch(collectionName, documentId, updateObject, dispatchFunction);
}

export function recordUpdateWithDispatch(collectionName, documentId, updateObject, dispatchFunction) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;

        return firestore().collection(collectionName).doc(documentId).update(
            updateObject
        ).then(() => {
            if (typeof dispatchFunction !== 'undefined') {
                dispatch(dispatchFunction);
            }
        });
    }
}

export function recordUpdate(collectionName, documentId, updateObject) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;

        return firestore().collection(collectionName).doc(documentId).update(
            updateObject
        );
    }
}

export function deleteRecord(collectionName, documentId) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        const deletedAt = getCurrentTimestamp(firestore);
        return firestore().collection(collectionName).doc(documentId).update(
            {
                isArchived: true,
                deletedAt
            }
        );
    }
}

export function hardDeleteRecord(collectionName, documentId) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        return firestore().collection(collectionName).doc(documentId).delete();
    }
}

export function createRecordWithDispatch(collectionName, dataObject, teamId, ...callbacksForDispatch) {
    const callbackFunction = (dispatch, data) => {
        if (typeof callbacksForDispatch !== 'undefined') {
            callbacksForDispatch.map(callback => {
                return dispatch(callback);
            });
        }
    };

    return createRecordWithCallback(collectionName, dataObject, teamId, callbackFunction);
}

export function createRecordWithCallback(collectionName, dataObject, teamId, callback) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        const createdAt = getCurrentTimestamp(firestore);
        const createdBy = getUserId(args.firebase);

        const defaultData = {
            createdAt,
            createdBy,
            isArchived: false
        };

        if (teamId) {
            defaultData['teamId'] = teamId;
        }

        const dataToAdd = Object.assign({}, defaultData, dataObject);

        return firestore().collection(collectionName).add(dataToAdd)
            .then((data) => {
                if (typeof callback !== 'undefined') {
                    callback(dispatch, data);
                }
            });
    }
}

export function createRecord(collectionName, dataObject, teamId) {
    return (dispatch, getState, args) => {
        let firestore = args.firebase.firestore;
        const createdAt = getCurrentTimestamp(firestore);
        const createdBy = getUserId(args.firebase);

        const defaultData = {
            createdAt,
            createdBy,
            isArchived: false
        };

        if (teamId) {
            defaultData['teamId'] = teamId;
        }

        const dataToAdd = Object.assign({}, defaultData, dataObject);

        // Update user last activity
        let lastActivityRecordedCollections = ['feedback', 'actionItems', 'comments'];
        if (lastActivityRecordedCollections.includes(collectionName)) {
            dispatch(recordUpdate('users', createdBy, {lastActivityAt: createdAt}));
        }

        return firestore().collection(collectionName).add(dataToAdd);
    }
}