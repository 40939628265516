import React from 'react'
import {compose} from "redux";
import {firestoreConnect, isLoaded, populate} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {groupBy} from "../../utilities/ArrayUtilities";

function EmailDomainMetric({users}) {
    if (!isLoaded(users)) {
        return null;
    }

    let domains = {};

    const nonTestUsers = users.filter(user => {
        return !user.email.includes('test');
    });

    if (!nonTestUsers) {
        return <div>No non-test users for email domain metric</div>
    }

    nonTestUsers.forEach(user => {
        const domain = user.email.replace(/.*@/, "");
        if (domains[domain]) {
            domains[domain].total = domains[domain].total + 1;
        } else {
            domains[domain] = {};
            domains[domain].total = 1;
        }
    });

    return (
        <div>
            <h3 style={{marginLeft: '5px'}}>Email Domains</h3>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Email Domain</strong></TableCell>
                            <TableCell><strong>Count</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(domains).map((domain) => (
                            <TableRow key={domain}>
                                <TableCell component="th" scope="row">
                                    {domain}
                                </TableCell>
                                <TableCell>{domains[domain].total}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        users: state.firestore.ordered.allUsers
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const currentUser = state.firebase.auth().currentUser;

        // Check for super admin to make sure we don't show insufficient privilege errors for our non-admin users
        if (currentUser.email !== "oalbrecht@gmail.com") {
            return [];
        }

        return [
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(EmailDomainMetric)
