import React from "react";
import logo from "../images/logo-new.svg";
import "./MobileWarning.css";

const MobileWarning = () => {
    return (
        <div className="MobileWarning">
            <div style={{textAlign: 'center'}}>
                <object height="50" width="50px" type="image/svg+xml" data={logo}>
                </object>
            </div>
            <p>Sorry, but Realtime Retro currently doesn't work on mobile devices.</p>
            <p>Please open <strong>realtimeretro.com</strong> on your computer to experience it on a larger screen.</p>
            <video autoPlay loop muted playsInline style={{width: "100%"}}>
                <source src="https://firebasestorage.googleapis.com/v0/b/retro-4c0b8.appspot.com/o/clippedAppPreview.mp4?alt=media&token=4091172e-cef6-4163-bf43-d4eeb9c77dea" type="video/mp4"/>
            </video>
        </div>
    )
};

export default MobileWarning;