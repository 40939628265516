export const firebase = function() {
    // Dev
    if (window.location.hostname === 'localhost') {
        return {
            apiKey: "AIzaSyB3f4aUaApWSqoXNc1KpiexxZ-nfQ3nFds",
            authDomain: "retro-dev-83f28.firebaseapp.com",
            databaseURL: "https://retro-dev-83f28.firebaseio.com",
            projectId: "retro-dev-83f28",
            storageBucket: "retro-dev-83f28.appspot.com",
            messagingSenderId: "723065146397",
            appId: "1:723065146397:web:1c5a3d592dbb1872930af4"
        }
    }

    // Prod
    return {
        apiKey: "AIzaSyCdiYxzD0GYL8OVSFcbA1ZQmaegrnh4RsQ",
        authDomain: "realtimeretro.com",
        databaseURL: "https://retro-4c0b8.firebaseio.com",
        projectId: "retro-4c0b8",
        storageBucket: "retro-4c0b8.appspot.com",
        messagingSenderId: "566066553672",
        appId: "1:566066553672:web:ba2b800343133dc7d1097d",
        measurementId: "G-28EBXJBV2Q"
    }
}();

export const reactReduxFirestoreConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
    enableLogging: true,
    logErrors: true,
    logListenerError: true,
};

export default { firebase, reactReduxFirestoreConfig }
