import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import StyledBadge from "./StyledBadge";
import './Feedback.css'
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import onBlur from '../utilities/Blur'
import {getUserInitials} from "../utilities/UserUtility";
import {getAvatarColor} from "../utilities/ColorUtility";
import ActionItemContainer from "../containers/ActionItemContainer";
import Confetti from 'react-dom-confetti';
import Moment from 'react-moment';

const useStyles = makeStyles(theme => ({
    card: {
        margin: '10px',
        border: "1px solid #dedede",
        backgroundColor: "white",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
        backgroundColor: red[500],
    },
    actionButtons: {
        marginLeft: 'auto',
        fontSize: '10px',
        marginBottom: '3px'
    },
    cardContent: {
        cursor: "pointer",
        paddingBottom: "0px"
    },
    cardActions: {
        paddingTop: "0px",
        paddingBottom: "0px",
    }
}));

const confettiConfig = {
    angle: 90,
    spread: 45,
    startVelocity: "30",
    elementCount: 50,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: "10px",
    height: "5px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const ActionItem = ({children, boardId, actionItem, teamId, avatarColor, isEditing, isOutstandingActionItem,
                        onClickUpdate, onClickToggleIsEditing, onClickDelete, onClickMarkCompleted
                  }) => {

    const classes = useStyles();
    const [showConfetti, setShowConfetti] = React.useState(false);
    const [feedback, setFeedback] = React.useState(actionItem.text);

    const submitPressed = () => {
        onClickUpdate(feedback);
        onClickToggleIsEditing(false);
    };

    const markCompleted = () => {
        setShowConfetti(true);
        setTimeout(() => {
            onClickMarkCompleted();
        }, 1500);
    };

    const handleKeypress = (e) => {
        if (e.ctrlKey) {
            setFeedback(feedback + '\n');
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            submitPressed();
        }
    };

    return (
        <div>
            <Card className={classes.card}>
                <CardContent
                    className={classes.cardContent}
                    onBlur={(e) => onBlur(e, () => onClickToggleIsEditing(false))}
                    onClick={() => {!isEditing && onClickToggleIsEditing(true);}}
                >
                    {isEditing &&
                    <form className="Feedback">
                        <TextField
                            autoFocus
                            className="standard-multiline-flexible"
                            size="small"
                            multiline
                            rowsMax="10"
                            variant="outlined"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            onKeyPress={(ev) => {handleKeypress(ev)}}
                        />
                        <Button position="right" className="FeedbackButton" variant="contained" color="primary"
                                onClick={submitPressed}
                        >
                            Save
                        </Button>
                    </form>
                    }
                    {!isEditing &&
                    <Typography variant="body2" color="textSecondary" className="CardText" component="p">
                        {actionItem.text}
                    </Typography>
                    }
                </CardContent>
                {!isEditing &&
                <CardActions className={classes.cardActions} disableSpacing>
                    {isOutstandingActionItem &&
                        <Moment fromNow date={actionItem.createdAt.toDate()} style={{color: "#909090"}}/>
                    }
                    <div className={classes.actionButtons}>
                        <IconButton aria-label="edit" onClick={() => onClickDelete(actionItem.id)}>
                            <Icon className="CardIcon">delete</Icon>
                        </IconButton>
                        <Tooltip title="Mark as Completed" aria-label="complete">
                            <IconButton aria-label="edit"  onClick={() => markCompleted()}>
                                <Icon className="CardIcon" style={{color: "#00d400", fontWeight: "bold"}}>check</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                </CardActions>
                }
            </Card>
            <div className="Feedback" style={{marginLeft: "82%"}}>
                <Confetti active={ showConfetti } config={ confettiConfig }/>
            </div>
        </div>
    );
};

export default ActionItem;
