import React from 'react'
import './Loading.css';
import CircularProgress from '@material-ui/core/CircularProgress'
// import { css } from "@emotion/core";

// const override = css`
//   display: block;
//   margin: 0 auto;
// `;

const Loading =() => {
  return (
       <div className="Loading">
          <CircularProgress />
          {/*<div style={{marginTop: '70px'}}>*/}
              {/*<GridLoader*/}
                  {/*css={override}*/}
                  {/*size={'20px'}*/}
                  {/*color={"#2196f3"}*/}
                  {/*loading={true}*/}
              {/*/>*/}
          {/*</div>*/}
      </div>
  )
};

export default Loading;

