import { connect } from 'react-redux'
import {
    actionItemUpdate,
    actionItemDelete,
    actionItemToggleIsEditing,
    actionItemSetIsComplete
} from "../redux/actions";
import ActionItem from '../components/ActionItem'

const mapStateToProps = (state, ownProps) => {
    return {
        isEditing: state.actionItem.isEditing[ownProps.actionItem.id]
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickUpdate: (text) => {
            dispatch(actionItemUpdate(ownProps.actionItem.id, text))
        },
        onClickToggleIsEditing: (isEditing) => {
            dispatch(actionItemToggleIsEditing(isEditing, ownProps.actionItem.id))
        },
        onClickDelete: () => {
            dispatch(actionItemDelete(ownProps.actionItem.id))
        },
        onClickMarkCompleted: () => {
            dispatch(actionItemSetIsComplete(ownProps.actionItem.id, true))
        },
    }
};

const ActionItemContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionItem);
export default ActionItemContainer