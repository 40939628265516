import { connect } from 'react-redux'
import BoardsFirestoreContainer from '../firestoreContainers/BoardsFirestoreContainer'

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

const BoardsFirestoreContainerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardsFirestoreContainer);
export default BoardsFirestoreContainerContainer