import React from 'react'
import '../App.css'
import {compose} from "redux";
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import BoardFirestoreContainerContainer from "../containers/BoardFirestoreContainerContainer";
import Loading from "../components/Loading";

function BoardsFirestoreContainer({boards, teamId}) {
    if (!isLoaded(boards)) {
        return <Loading/>;
    }

    return (
        <BoardFirestoreContainerContainer boards={boards} teamId={teamId}/>
    )
}

export default compose(
    firestoreConnect((props, state) => {
        if (!props.teamId) return [];

        return [
            {
                collection: 'boards',
                where: [
                    ['teamId', '==', props.teamId],
                    ['isArchived', '==', false],
                ],
                orderBy: [
                    ['createdAt', 'desc']
                ]
            }
        ];
    }),
    connect((state, props) => ({
        boards: state.firestore.ordered.boards
    }))
)(BoardsFirestoreContainer)
