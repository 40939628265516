import { connect } from 'react-redux'
import {
    updateTeam
} from "../redux/actions";
import InitialTeamSetup from '../components/InitialTeamSetup'

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.firestore.data.user
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClickSetTeamName: (name) => {
            return dispatch(updateTeam(ownProps.teamId, name));
        }
    }
};

const InitialTeamSetupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(InitialTeamSetup);
export default InitialTeamSetupContainer