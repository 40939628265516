import React, {useState} from 'react';
import './CreateBoardColumn.css';
import {IconButton, Tooltip} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const CreateBoardColumn = ({boardColumnShowCreateForm, onClickToggleDisplayForm, onClickBoardColumnCreate, boardId}) => {
    const createColumn = () => {
        onClickBoardColumnCreate("", boardId).then(() => {
            onClickToggleDisplayForm(true)
        });
    };

    return (
        <div>
            <Tooltip title="Add Column" aria-label="add">
                <IconButton aria-label="delete" className="AddColumnButton" onClick={ () => createColumn()}>
                    <AddIcon/>
                </IconButton>
            </Tooltip>
        </div>
    )
};

export default CreateBoardColumn;