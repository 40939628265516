import React from 'react'
import '../App.css'
import {compose} from "redux";
import {firestoreConnect, isLoaded, populate, isEmpty} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import Share from "../components/Share";
import Loading from "../components/Loading";
import {shareUrlCreate, setTeamIsCreating, setTeamTabViewIndex, userUpdate} from "../redux/actions";


function ShareFirestoreContainer({shareUrls, teamId,
                                     getShareUrl

}) {
    if (!isLoaded(shareUrls)) {
        return <Loading/>;
    }

    if (isEmpty(shareUrls)) {
        getShareUrl();
        return (<Loading/>);
    }

    const domain = window.location.hostname === 'localhost' ? "http://localhost:3000/signIn/" : "https://app.realtimeretro.com/signIn/";
    const shareUrl = domain + shareUrls[0].token;
    const hasTokenBeenGenerated = typeof shareUrls[0].token !== "undefined";

    return (
        <Share teamId={teamId} shareUrl={shareUrl} hasTokenBeenGenerated={hasTokenBeenGenerated}/>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        shareUrls: state.firestore.ordered.shareUrls,
        teamId: ownProps.teamId
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getShareUrl: () => {
            return dispatch(shareUrlCreate(ownProps.teamId));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        if (!props.teamId) return [];

        let daysOffset = 1;
        var offsetDate = new Date();
        offsetDate.setDate(offsetDate.getDate() - daysOffset);
        return [
            {
                collection: 'shareUrls',
                where: [
                    ['teamId', '==', props.teamId],
                    ['createdAt', '>', offsetDate]
                ]
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ShareFirestoreContainer)
