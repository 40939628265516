import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import"./CreateBoard.css"
import onBlur from "../utilities/Blur"
import Drawer from '@material-ui/core/Drawer';
import Checkbox  from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import BoardTemplate from "../containers/BoardTemplate";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: '20px',
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const CreateBoard = ({user, userId, chosenBoardTemplateName, chosenBoardTemplateColumns, isFirstBoard,
                         showTemplatePicker, boardShowCreateForm, onClickToggleDisplayForm, onClickBoardCreate, boardShowChooseTemplatePicker}) => {
        const classes = useStyles();
        const [boardName, changeBoardName] = useState('');
        const [showDrawer, setShowDrawer] = useState(false);
        const [limitHearts, setLimitHearts] = useState(false);
        const [heartLimit, setHeartLimit] = useState(5);

        chosenBoardTemplateName = isFirstBoard && chosenBoardTemplateName === 'Same as last retro' ? 'Classic' : chosenBoardTemplateName;
        const createBoard = () => {
            const heartLimitForCreate = limitHearts === true ? heartLimit : null;
            onClickBoardCreate(boardName, chosenBoardTemplateColumns, heartLimitForCreate);
        };

        return (
            <div className="CreateBoard" onBlur={(e) => onBlur(e, () => onClickToggleDisplayForm(false))}>
                <Button color="primary" variant="contained" onClick={() => {
                    onClickToggleDisplayForm(true);
                    setShowDrawer(true);
                }} style={{marginRight: '26px'}}>New Retro</Button>

                <Drawer anchor="right" open={showDrawer} onClose={() => {
                    setShowDrawer(false)
                }}>
                    <AppBar position="static" color="primary">
                        <Toolbar>
                            <Typography variant="h6">
                                New Retro
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={{margin: '15px', marginTop: '0px'}}>
                        <br/>
                        <form onSubmit={(e) => {
                            createBoard();
                            changeBoardName('');
                            setShowDrawer(false);
                            e.preventDefault();
                        }}>
                            <FormGroup>
                                <TextField value={boardName} autoFocus onChange={(e) => changeBoardName(e.target.value)}
                                           id="outlined-basic" size="small" label="Retro Name" variant="outlined"/>
                            </FormGroup>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <TextField
                                    id="retro-template-input"
                                    label="Retro Template"
                                    onClick={() => {
                                        boardShowChooseTemplatePicker(true)
                                    }}
                                    variant="outlined"
                                    size="small"
                                    style={{cursor: 'pointer'}}
                                    value={chosenBoardTemplateName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={limitHearts}
                                            onChange={(e) => {
                                                setLimitHearts(e.target.checked)
                                            }}
                                            value="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Limit Feedback Likes"
                                />
                                {limitHearts &&
                                <TextField value={heartLimit} onChange={(e) => setHeartLimit(e.target.value)}
                                           id="outlined-basic" size="small" label="Like Limit Per User"
                                           variant="outlined"
                                           type="number"/>
                                }
                            </FormGroup>
                            <Button style={{marginTop: '10px'}} color="primary" variant="contained"
                                    type="submit">Create</Button>
                        </form>
                    </div>
                </Drawer>
                <BoardTemplate isFirstBoard={isFirstBoard}/>
            </div>
        );
};

export default CreateBoard;