
let avatarColorsForUsernames = {};
const avatarColors = [
    '#03a9f4',
    '#cddc39',
    '#607d8b',
    '#00bcd4',
    '#ffeb3b',
    '#9e9e9e',
    '#2196f3',
    '#9c27b0',
    '#8bc34a',
    '#ffc107',
    '#673ab7',
    '#ff5722',
    '#e91e63',
    '#795548',
    '#3f51b5',
    '#ff9800',
    '#009688',
    '#4caf50',
    '#f44336',
];
export const getAvatarColor = (userName) => {
    if (!avatarColorsForUsernames[userName]) {
        if (avatarColors.length > 0) {
            avatarColorsForUsernames[userName] = avatarColors.pop();
        } else {
            // If we run out of color options, just default to one
            avatarColorsForUsernames[userName] = '#2196f3';
        }
    }

    return avatarColorsForUsernames[userName];
};