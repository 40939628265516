import React from 'react'
import CreateBoardContainer from "../containers/CreateBoardContainer";
import './CreateNewBoard.css'

const CreateNewBoard = ({teamId, userId, user}) => {
    return (
        <div>
            <div className="CreateBoardContainerWrapper">
                <div className="CreateBoardContainer">
                    <CreateBoardContainer user={user} userId={userId} teamId={teamId} isFirstBoard={true}/>
                </div>
            </div>
        </div>
    )
};

export default CreateNewBoard;