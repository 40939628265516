import React from "react";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";

const CreateTeam = ({user,
                        onClickCreateTeam}) => {
    const [teamName, setTeamName] = React.useState('');

    const doneButtonClicked = () => {
        onClickCreateTeam(teamName, user.email, user.displayName);
        setTeamName('');
    };

    return (
        <form onSubmit={(e) => {e.preventDefault(); doneButtonClicked(teamName);}}>
            <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Team Name"
                label="Team Name"
                fullWidth
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                autoFocus
            />
            <div style={{marginTop: '10px'}}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default CreateTeam;