import React from 'react';
import '../App.css'
import connect from "react-redux/es/connect/connect";
import { useHistory } from "react-router-dom";
import {createSharedTeamUser, setInitialUserData} from "../redux/actions";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {getUserId} from "../utilities/UserUtility";
import Loading from "../components/Loading";
import {isLoaded} from "react-redux-firebase";

function SharedRedirectFirestoreContainer({match: { params }, user,
                            createTeamUser, updateUserWithUserAgent
}) {
    let history = useHistory();

    if (!isLoaded(user)) {
        return <Loading/>;
    }
    const tokenWithTeam = params.token;

    // Create a team user and include the token with the request.
    // In firestore rules, if the token matches the team, then allow the team user to be created.
    const splitApartTokenWithTeam = tokenWithTeam.split('_');
    const teamId = splitApartTokenWithTeam[0];
    const token = splitApartTokenWithTeam[1];
    createTeamUser(teamId, token, user.email, user.displayName).then(() => {
        updateUserWithUserAgent(user.id);
        history.push('/board');
    });
    return null;
}

const mapStateToProps = (state, ownProps) => {
    const users = state.firestore.ordered.users;
    return {
        user: users && users.length > 0 && users[0]
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createTeamUser: (teamId, token, email, displayName) => {
            return dispatch(createSharedTeamUser(teamId, token, email, displayName))
        },
        updateUserWithUserAgent: (userId) => {
            dispatch(setInitialUserData(userId));
        }
    }
};

export default compose(
    firestoreConnect((props, state) => {
        const userId = getUserId(state.firebase);
        return [
            {
                collection: 'users',
                doc: userId,
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SharedRedirectFirestoreContainer)
