import LocalStorageUtility from "./LocalStorageUtility";

export function getUserId(firebase) {
    const impersonationUserId = LocalStorageUtility().getAdminImpersonationUserId();
    if (impersonationUserId) return impersonationUserId;


    if (typeof firebase.auth === 'function') {
        if (firebase.auth().currentUser === null) {
            document.location = "/signIn";
            return null;
        }

        return firebase.auth().currentUser.uid;
    }

    return firebase.auth.uid;
}

export function getUserInitials(firebaseDisplayName) {
    return firebaseDisplayName.split(" ").map(name => {
        if (name.length > 0) {
            return name[0].toUpperCase();
        }
        return "😀";
    }).join('');
}