import { connect } from 'react-redux'
import BoardFirestoreContainer from '../firestoreContainers/BoardFirestoreContainer'

const getCurrentBoard = (state, ownProps) => {
    const lastBoard = ownProps.boards[0];
    if (!state.board.currentBoardId) {
        return lastBoard;
    }

    let boardsMatchingCurrentBoardId = ownProps.boards.filter(board => board.id === state.board.currentBoardId);
    if (boardsMatchingCurrentBoardId.length === 0) {
        return lastBoard;
    }

    let boardMatchingCurrentBoardId = boardsMatchingCurrentBoardId[0];
    if (boardMatchingCurrentBoardId === null) {
        return lastBoard;
    }

    return boardMatchingCurrentBoardId;
};

const mapStateToProps = (state, ownProps) => {
    return {
        board: getCurrentBoard(state, ownProps)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

const BoardFirestoreContainerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardFirestoreContainer);
export default BoardFirestoreContainerContainer