import React from "react";
import {Grid} from "@material-ui/core";
import UserMetric from "../../firestoreContainers/admin/UserMetric";
import ActiveUserMetric from "../../firestoreContainers/admin/ActiveUsersMetric";
import TeamMetric from "../../firestoreContainers/admin/TeamMetric";
import EmailDomainMetric from "../../firestoreContainers/admin/EmailDomainMetric";
import ActiveTeamsMetric from "../../firestoreContainers/admin/ActiveTeamsMetric";

const AdminMetrics = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <UserMetric/>
            </Grid>
            <Grid item xs={6}>
                <ActiveUserMetric/>
            </Grid>
            <Grid item xs={6}>
                <TeamMetric/>
            </Grid>
            <Grid item xs={6}>
                <ActiveTeamsMetric/>
            </Grid>
            <Grid item xs={6}>
                <EmailDomainMetric/>
            </Grid>
        </Grid>
    )
};

export default AdminMetrics;
