import React from 'react'
import DeleteModal from "./DeleteModal";
import Icon from "@material-ui/core/Icon/Icon";
import IconButton from "@material-ui/core/IconButton/IconButton";

const DeleteBoard = ({onClickDelete}) => {
    return (
        <DeleteModal onClickDelete={onClickDelete} recordName='retro'>
            <IconButton aria-label="delete board" color="secondary">
                <Icon>delete</Icon>
            </IconButton>
        </DeleteModal>
    );
};

export default DeleteBoard;