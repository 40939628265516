export const groupBy = function(data, key) { // `data` is an array of objects, `key` is the key (or property accessor) to group by
                                    // reduce runs this anonymous function on each element of `data` (the `item` parameter,
                                    // returning the `storage` parameter at the end
    return data.reduce(function(storage, item) {
        // get the first instance of the key by which we're grouping
        var group = item[key];

        // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
        storage[group] = storage[group] || [];

        // add this item to its group within `storage`
        storage[group].push(item);

        // return the updated storage to the reduce function, which will then loop through the next
        return storage;
    }, {}); // {} is the initial value of the storage
};

/**
 * Takes an object and returns an array where the value's of the object are array items and the keys
 * are stored under the 'id' field.
 *
 * e.g. {'abc123': {one: '1'}, def456: {two: '2'}} => [{id: 'abc123', one: '1'}, {id: 'def456', two: '2'}]
 * @param object
 * @returns {any[][]}
 */
export const objectToArray = (originalObject) => {
    let object = JSON.parse(JSON.stringify(originalObject));
    return Object.keys(object).map((key) => {
        let objectToReturn = object[key];
        if (objectToReturn) {

            objectToReturn.id = key;
        }
        return objectToReturn;
    });
};