import React from 'react';
import queryString from 'query-string';
import CurrentTeamFirestoreContainer from "../../firestoreContainers/CurrentTeamFirestoreContainer";
import NavBarContainer from "../../containers/NavBarContainer";
import Slack from '../../firestoreContainers/SlackFirestoreContainer';

const SlackPage = ({location}) => {
    let requestParams = queryString.parse(location.search);

    return (
        <div>
            <CurrentTeamFirestoreContainer>
                <NavBarContainer/>
                <Slack code={requestParams.code}/>
            </CurrentTeamFirestoreContainer>
        </div>
    )
};

export default SlackPage;