import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField/TextField";
import ShareIcon from "@material-ui/icons/Share";
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button';
import './Share.css'
import BackButton from "./BackButton";

const Share = ({shareUrl, hasTokenBeenGenerated
}) => {
    const defaultShareButtonText = "Copy Share Link";
    const [buttonText, setButtonText] = React.useState(defaultShareButtonText)
    function copyButtonClicked() {
        navigator.clipboard.writeText(shareUrl);
        setButtonText("Copied!");
        setTimeout(() => {
            setButtonText(defaultShareButtonText);
        }, 1000);
    }
    
    if (!hasTokenBeenGenerated) {
        shareUrl = "Generating link...";
    }

    return (
        <div>
            <BackButton/>
            <div className="ShareForm">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <div style={{width: 350}}>
                        <Paper className="SharePaper" elevation={2}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                            >
                                <Avatar color="primary">
                                    <ShareIcon />
                                </Avatar>
                                <div className="TeamFormMargin">
                                    <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
                                        Share
                                    </Typography>
                                </div>
                                <Grid item xs={10} md={10}>
                                    <p>To share your retro boards with your team, copy and share the following link:</p>
                                    <br/>
                                    <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Team Share Link"
                                    fullWidth
                                    value={shareUrl}
                                    />
                                    {hasTokenBeenGenerated &&
                                        <>
                                            <Button color="primary" size="large" variant="contained" disableElevation
                                                    onClick={() => {
                                                        copyButtonClicked();
                                                    }}
                                                    style={{marginTop: "5px", width: "190px"}}
                                            >{buttonText}</Button>
                                            <p>
                                                <small style={{color: '#ff3939', width: "90%"}}>
                                                    Note: The share link only works for <b>7 days</b>. If it expires, visit this page again for a new one.
                                                </small>
                                            </p>
                                        </>
                                    }
                                    {!hasTokenBeenGenerated &&
                                        <>
                                            <br/>
                                            <br/>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
            </div>
        </div>
    )
};

export default Share;
