import React from 'react';
import LocalStorageUtility from '../utilities/LocalStorageUtility';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";

const ShareDialog = () => {
    const [open, setOpen] = React.useState(LocalStorageUtility().getShowShareTeam() === 'true');

    const hideShareDialog = () => {
        LocalStorageUtility().setShowShareTeam('false');
        setOpen(false);
    };

    return (
      <div>
          <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={open}
              aria-labelledby="max-width-dialog-title"
          >

              <DialogTitle id="max-width-dialog-title">Retros are more fun when your team can join.</DialogTitle>
              <DialogContent>
                  <DialogContentText color="primary">
                      Click the <Button aria-label="share" variant="contained" color="primary"
                                        style={{minWidth: '20px', width: '20px'}}>
                      <Icon>share</Icon>
                  </Button> button in the top-right to share your retro board.
                      <br/><br/>
                  <video autoPlay loop muted playsInline style={{width: "100%", maxWidth: "600px", height: "100%"}}>
                      <source src="https://firebasestorage.googleapis.com/v0/b/retro-4c0b8.appspot.com/o/clippedAppPreview.mp4?alt=media&token=4091172e-cef6-4163-bf43-d4eeb9c77dea" type="video/mp4"/>
                  </video>
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button color="primary" variant="contained" onClick={hideShareDialog}>
                      Got it
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
    );
};

export default ShareDialog;