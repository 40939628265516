import React, {useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import TextField from "@material-ui/core/TextField/TextField";
import Group from "@material-ui/icons/Group";
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button';
import './TeamForm.css'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import DeleteModal from "./DeleteModal";
import CreateTeamContainer from "../containers/CreateTeamContainer";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        marginTop: '7px',
        marginLeft: '0px'
    },
}));

const TeamForm = ({ userId, teamId, name, teamUsers, teamCreatedAt, teams, isCreating, allowDelete, allowDeletingOwnTeamUser,
                      onClickDoneEditing, onClickTeamUserCreate, onClickTeamUserDelete, onClickTeamDelete
}) => {
    const classes = useStyles();
    const [teamName, setTeamName] = React.useState(name);
    const [showSaveButton, setShowSaveButton] = React.useState(false);

    // Used to determine when the team switched, so we can update the team name accordingly
    const [teamCreatedAtView, setTeamCreatedAtView] = React.useState(teamCreatedAt);
    useEffect(() => {
        if (teamCreatedAt !== teamCreatedAtView) {
            setTeamName(name);
            setTeamCreatedAtView(teamCreatedAt);
        }
    });

    let history = useHistory();

    function doneButtonClicked(teamName) {
        onClickDoneEditing(teamName).then(() => {
            setShowSaveButton(false);
        });
    }

    function onClickDelete() {
        onClickTeamDelete(teamUsers);
    }

    function allowDeletingTeamUser(teamUserId) {
        return allowDeletingOwnTeamUser ? true : teamUserId !== userId;
    }

    return (
        <div className="TeamForm">
            {isCreating &&
                <CreateTeamContainer/>
            }
            {!isCreating &&
                <div>
                    <br/>
                    <form onSubmit={(e) => {e.preventDefault(); doneButtonClicked(teamName);}}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Team Name"
                            label="Team Name"
                            fullWidth={true}
                            value={teamName}
                            onChange={(e) => {setTeamName(e.target.value); setShowSaveButton(true);}}
                            autoFocus
                        />
                        {showSaveButton &&
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.button}
                                type="submit"
                            >
                                Save
                            </Button>
                        }
                    </form>
                    <br/><br/>
                    {teamUsers &&
                    <div>
                        <Typography component="p">
                            <strong>Team Members</strong>
                        </Typography>
                        <List>
                            {teamUsers.map((teamUser, index) => (
                                <ListItem key={index}>
                                    {teamUser &&
                                        <>
                                            <ListItemText style={{overflow: 'hidden'}}
                                                          primary={teamUser.displayName}
                                                          secondary={teamUser.email}
                                            />
                                            {teamUsers.length > 1 && (allowDeletingTeamUser(teamUser.userId)) &&
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete" onClick={(e) => {
                                                    onClickTeamUserDelete(teamUser.id)
                                                }}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                            }
                                        </>
                                    }
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    }

                    <div>
                        {allowDelete &&
                            <DeleteModal onClickDelete={onClickDelete} recordName='team' className={classes.button}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                >
                                    Delete Team
                                </Button>
                            </DeleteModal>
                        }
                    </div>
                </div>
            }
        </div>
    )
};

export default TeamForm;
