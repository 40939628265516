import React from 'react';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import './CreateComment.css'

const CreateComment = ({currentUser,
                           onClickToggleDisplayForm, onClickCommentCreate
}) => {
    const [commentText, setCommentText] = React.useState('');

    const submitPressed = () => {
        if (commentText.trim().length === 0) {
            return;
        }

        onClickCommentCreate(commentText, currentUser.displayName);
        setCommentText('');
    };

    const handleKeypress = (e) => {
        if (e.ctrlKey) {
            setCommentText(commentText + '\n');
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            submitPressed();
        }
    };

    return (
        <div className="CreateComment">
            <TextField
                id="standard-multiline-flexible"
                variant="outlined"
                // label="Add a comment"
                placeholder="Add a comment..."
                multiline
                fullWidth
                rowsMax="10"
                value={commentText}
                autoFocus
                onChange={(e) => setCommentText(e.target.value)}
                onKeyPress={(ev) => {handleKeypress(ev)}}
            />
            <Button position="right" className="CommentSubmit" variant="contained" color="primary"
                onClick={submitPressed}
            >
                Save
            </Button>
        </div>
    )
};

export default CreateComment;