import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import * as actions from "./actions";
import LocalStorageUtility from "../utilities/LocalStorageUtility";
import {BOARD_CHOOSE_TEMPLATE} from "./actions";
import {BOARD_SHOW_CHOOSE_TEMPLATE_PICKER} from "./actions";

function board(state = { boardShowCreateForm: false, isEditing: false, currentBoardId: null, chosenBoardTemplateName: 'Same as last retro', chosenBoardTemplateColumns: [], showTemplatePicker: false}, action) {
  switch (action.type) {
      case actions.BOARD_TOGGLE_SHOW_CREATE_FORM:
          return Object.assign({}, state, {
              boardShowCreateForm: action.shouldShowForm
          });
      case actions.BOARD_IS_EDITING:
          return Object.assign({}, state, {
              isEditing: action.isEditing
          });
      case actions.BOARD_SET_VIEW_ID:
          return Object.assign({}, state, {
              currentBoardId: action.boardId
          });
      case actions.BOARD_CHOOSE_TEMPLATE:
          return Object.assign({}, state, {
              chosenBoardTemplateName: action.templateName,
              chosenBoardTemplateColumns: action.columnNames
          });
      case actions.BOARD_SHOW_CHOOSE_TEMPLATE_PICKER:
          return Object.assign({}, state, {
              showTemplatePicker: action.shouldShow,
          });
      default:
          return state;
  }
}

function column(state = { boardColumnShowCreateForm: false, isEditing: {}}, action) {
    switch (action.type) {
        case actions.BOARD_COLUMN_TOGGLE_SHOW_CREATE_FORM:
            return Object.assign({}, state, {
                boardColumnShowCreateForm: action.shouldShowForm
            });
        case actions.COLUMN_IS_EDITING:
            return {
                ...state,
                isEditing: Object.assign({}, state.isEditing, {
                    [action.columnId]: action.isEditing
                })
            };
        default:
            return state;
    }
}

function feedback(state = { isEditing: {}}, action) {
    switch (action.type) {
        case actions.FEEDBACK_IS_EDITING:
            return {
                ...state,
                isEditing: Object.assign({}, state.isEditing, {
                    [action.feedbackId]: action.isEditing
                })
            };
        default:
            return state;
    }
}

function actionItem(state = { isEditing: {}}, action) {
    switch (action.type) {
        case actions.ACTION_ITEM_IS_EDITING:
            return {
                ...state,
                isEditing: Object.assign({}, state.isEditing, {
                    [action.actionItemId]: action.isEditing
                })
            };
        default:
            return state;
    }
}

function comment(state = { isEditing: {}}, action) {
    switch (action.type) {
        case actions.COMMENT_IS_EDITING:
            return {
                ...state,
                isEditing: Object.assign({}, state.isEditing, {
                    [action.commentId]: action.isEditing
                })
            };
        default:
            return state;
    }
}

function team(state = {teamTabViewIndex: 0, currentlyViewingTeamId: null, isCreatingTeam: false}, action) {
    switch (action.type) {
        case actions.TEAM_SET_TAB_VIEW_INDEX:
            return {
                ...state,
                teamTabViewIndex: action.index
            };
        case actions.TEAM_SET_CURRENTLY_VIEWING_TEAM:
            LocalStorageUtility().setCurrentlyViewingTeamId(action.teamId);

            return {
                ...state,
                currentlyViewingTeamId: action.teamId
            };
        case actions.TEAM_IS_CREATING:
            return {
                ...state,
                isCreatingTeam: action.isCreating
            };
        default:
            return state;
    }
}

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    actionItem,
    board,
    column,
    comment,
    feedback,
    team,
});

export default rootReducer
