import { useFirebase } from 'react-redux-firebase'
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import './SignInPage.css'
import { useHistory } from "react-router-dom";
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import signInImage from '../images/teamworkFilter2Resized.jpg';
import logo from "../images/logo-new.svg";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(' + signInImage + ')',
        backgroundRepeat: 'no-repeat',
        // backgroundColor:
        //     theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // backgroundBlendMode: 'color-dodge',
        backgroundColor: '#444'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignInPage({ match: { params } }) {
    const classes = useStyles();
    const history = useHistory();
    const token = params.token;

    const firebase = useFirebase();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <div style={{textAlign: 'center', marginBottom: '10px'}}>
                        <object height="50px" width="50px" type="image/svg+xml" data={logo}>
                        </object>
                    </div>
                    <Typography className="SignInPageTitle" component="h1" variant="h5">
                        Welcome to Realtime Retro
                    </Typography>
                    <form className={classes.form} noValidate>
                        <StyledFirebaseAuth
                            uiConfig={{
                                signInFlow: 'popup',
                                signInSuccessUrl: '/board',
                                signInOptions: [
                                    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                                    firebase.auth.EmailAuthProvider.PROVIDER_ID
                                ],
                                callbacks: {
                                    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                                        firebase.handleRedirectResult(authResult).then(() => {
                                            if (typeof window.FS !== 'undefined') {
                                                window.FS.identify(authResult.user.uid, {
                                                    displayName: authResult.user.displayName,
                                                    email: authResult.user.email,
                                                });
                                            }

                                            if (token) {
                                                history.push('/sharedRedirect/' + token);
                                            } else {
                                                history.push('/redirect');
                                            }
                                        });
                                        return false;
                                    },
                                },
                            }}
                            firebaseAuth={firebase.auth()}
                        />
                        <Box mt={5}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                <Link color="inherit" href="https://realtimeretro.com/terms">
                                    Terms
                                </Link>
                                <Link  style={{marginLeft: '6px'}} color="inherit" href="https://realtimeretro.com/privacy">
                                    Privacy Policy
                                </Link>
                                <Link style={{marginLeft: '6px'}} color="inherit" href="https://realtimeretro.com/gdpr">
                                    GDPR
                                </Link>
                                <br/>
                                {'Copyright © '}
                                <Link color="inherit" href="https://material-ui.com/">
                                    Realtime Retro
                                </Link>{' '}
                                {new Date().getFullYear()}
                            </Typography>
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}