import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import StyledBadge from "./StyledBadge";
import './Feedback.css'
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import onBlur from '../utilities/Blur'
import {getUserInitials} from "../utilities/UserUtility";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const useStyles = makeStyles(theme => ({
    card: {
        margin: '10px',
        border: "1px solid #dedede"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
        backgroundColor: red[500],
    },
    actionButtons: {
        marginLeft: 'auto',
        fontSize: '10px',
        marginRight: '10px',
    },
    cardContent: {
        cursor: "pointer"
    }
}));

const Feedback = ({children, userName, avatarColor, createdByLoggedInUser, text, commentTotal, boardId, feedbackId, isEditing, isHearted, totalHearts, allowHearting,
                      onClickFeedbackHeartToggle, onClickFeedbackUpdate, onClickToggleIsEditing, onClickDelete
    }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [feedback, setFeedback] = React.useState(text);

    const userInitials = getUserInitials(userName);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const submitPressed = () => {
        onClickFeedbackUpdate(feedback);
        onClickToggleIsEditing(false);
    };

    const handleKeypress = (e) => {
        if (e.ctrlKey) {
            setFeedback(feedback + '\n');
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            submitPressed();
        }
    };

    return (
        <Card className={classes.card}>
            <CardContent
                className={createdByLoggedInUser ? classes.cardContent : ''}
                onBlur={(e) => onBlur(e, () => onClickToggleIsEditing(false))}
                onClick={() => {!isEditing && createdByLoggedInUser && onClickToggleIsEditing(true);}}
            >
                {isEditing &&
                    <form className="Feedback">
                        <TextField
                            autoFocus
                            className="standard-multiline-flexible"
                            size="small"
                            multiline
                            rowsMax="10"
                            variant="outlined"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            onKeyPress={(ev) => {handleKeypress(ev)}}
                        />
                        <Button position="right" className="FeedbackButton" variant="contained" color="primary"
                                onClick={submitPressed}
                        >
                            Save
                        </Button>
                    </form>
                }
                {!isEditing &&
                    <Typography variant="body2" color="textSecondary" className="CardText" component="p">
                        {text}
                    </Typography>
                }
            </CardContent>
            {!isEditing &&
                <CardActions disableSpacing>
                    <Avatar aria-label="avatar" className="CardAvatar" style={{backgroundColor: avatarColor}}>
                        {userInitials}
                    </Avatar>
                    <div className={classes.actionButtons}>
                        {createdByLoggedInUser &&
                        <IconButton aria-label="edit" onClick={() => onClickDelete(feedbackId)}>
                            <Icon className="CardIcon">delete</Icon>
                        </IconButton>
                        }
                        <IconButton aria-label="favorite" onClick={() => {
                            onClickFeedbackHeartToggle()
                        }}>
                            <StyledBadge badgeContent={totalHearts} color="secondary">
                                <Icon className="CardIcon" color={(isHearted) ? 'secondary' : 'inherit'}>favorite</Icon>
                            </StyledBadge>
                        </IconButton>
                        <Tooltip title="Comments" aria-label="comments">
                            <IconButton
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <StyledBadge badgeContent={commentTotal} color="primary">
                                    <Icon className="CardIcon">comment</Icon>
                                </StyledBadge>
                            </IconButton>
                        </Tooltip>
                    </div>
                </CardActions>
            }
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {children}
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default Feedback;
