import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({
    dsn: "https://a9fa31a230e04053ab8da50d646507e5@o438575.ingest.sentry.io/5403574",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
